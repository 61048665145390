import axios from "axios";
import { API_URL } from "../config/config";
import { getHeaderOptions, getHeaderOptions2 } from "./header";

/**
 * @description : Common for all API functions.
 * @author : Bhargav Lakhani
 * @since : 22 Dec 2021
 */

const API_ENDPOINT = API_URL + "/v1/auth";
const API_UserEndPoint = API_URL + "/v1/user/admin";
const API_User = API_URL + "/v1/user";
const API_CMS = API_URL + "/v1/cms";
const API_AUDIO = API_URL + "/v1/warning-audio";
const API_EmergencyContact = API_URL + "/v1/emergency-contact";
const API_PanicEvents = API_URL + "/v1/alarm";
const API_DeviceInventory = API_URL + "/v1/device-inventory";
const API_Subscription = API_URL + "/v1/subscription";

export function signIn(params) {
  return axios.post(`${API_ENDPOINT}/admin-signin`, params);
}

export function forgotPassword(params) {
  return axios.post(`${API_ENDPOINT}/admin-forgot-password`, params);
}

export function resetPassword(creds, token) {
  return axios.post(`${API_ENDPOINT}/admin-reset-password/${token}`, creds);
}

export function getAllPosts(params) {
  return axios.get(`${API_ENDPOINT}/post-statistics`, {
    headers: getHeaderOptions(),
    params: params,
  });
}

export function getStatistics() {
  return axios.get(`${API_ENDPOINT}/new-customer-registartion-in-7-days`, {
    headers: getHeaderOptions(),
  });
}

export function getActiveWeeklyCustomers() {
  return axios.get(`${API_ENDPOINT}/cutomers-active-in-last-7-days`, {
    headers: getHeaderOptions(),
  });
}

export function getActiveDailyCustomers() {
  return axios.get(`${API_ENDPOINT}/cutomers-active-in-last-24-hours`, {
    headers: getHeaderOptions(),
  });
}

export function getActiveMonthlyCustomers() {
  return axios.get(`${API_ENDPOINT}/cutomers-not-active-in-last-30-days`, {
    headers: getHeaderOptions(),
  });
}

export function getWeeklyExperineces() {
  return axios.get(`${API_ENDPOINT}/weekly-experineces`, {
    headers: getHeaderOptions(),
  });
}

export function getUserList(params) {
  return axios.get(`${API_UserEndPoint}/list-all-users`, {
    headers: getHeaderOptions(),
    params: params,
  });
}

export function createUser(data) {
  console.log("api fetch data=> ",data)
  return axios.post(`${API_UserEndPoint}/create-user`, data, {
    headers: getHeaderOptions(),
  });
}

export function updateUserStatus(id, status) {
  return axios.patch(`${API_UserEndPoint}/active-deactive-user/${id}`, status, {
    headers: getHeaderOptions(),
  });
}

export function getUserById(id) {
  return axios.get(`${API_UserEndPoint}/get-user-by-id/${id}`, {
    headers: getHeaderOptions(),
  });
}

export function updateUserById(id, data) {
  return axios.put(`${API_UserEndPoint}/update-user-details/${id}`, data, {
    headers: getHeaderOptions(),
  });
}

export function exportUser(params) {
  return axios.get(`${API_UserEndPoint}/export-users`, {
    headers: getHeaderOptions(),
    params: params,
  });
}

export function createAdmin(data) {
  return axios.post(`${API_ENDPOINT}/create-admin`, data, {
    headers: getHeaderOptions(),
  });
}

export function updateAdminStatus(id, data) {
  return axios.patch(`${API_ENDPOINT}/active-deactive-admin/${id}`, data, {
    headers: getHeaderOptions(),
  });
}

export function getAdminById(id) {
  return axios.get(`${API_ENDPOINT}/get-admin-by-id/${id}`, {
    headers: getHeaderOptions(),
  });
}

export function updateAdminById(id, data) {
  return axios.put(`${API_ENDPOINT}/update-admin-details/${id}`, data, {
    headers: getHeaderOptions(),
  });
}

export function exportAdmin() {
  return axios.get(`${API_ENDPOINT}/report/export-admin`, {
    headers: getHeaderOptions(),
  });
}

// 27-01-2022

export function getFlaggedPosts(params) {
  return axios.get(`${API_ENDPOINT}/flagged-posts`, {
    headers: getHeaderOptions(),
    params: params,
  });
}

export function flaggedPostAction(flag_id, action_type) {
  return axios.patch(
    `${API_ENDPOINT}/action-for-flagged-posts/${flag_id}`,
    { action_type: action_type },
    {
      headers: getHeaderOptions(),
    }
  );
}

// 28-01-2022

export function getPostData(params) {
  return axios.get(`${API_ENDPOINT}/post-data`, {
    headers: getHeaderOptions(),
    params: params,
  });
}

export function getBannedPosts(params) {
  delete params.experience_type;
  return axios.get(`${API_ENDPOINT}/banned-posts`, {
    headers: getHeaderOptions(),
    params: params,
  });
}

export function banPost(experience_id) {
  return axios.patch(
    `${API_ENDPOINT}/ban-post/${experience_id}`,
    {},
    {
      headers: getHeaderOptions(),
    }
  );
}

// 31-01-2022

// export function getEmailTemplates() {
//   return axios.get(`${API_ENDPOINT}/list-all-email-templates`, {
//     headers: getHeaderOptions(),
//   });
// }

export function setEmailTemplateStatus(eTempId, status) {
  return axios.patch(
    `${API_ENDPOINT}/active-deactive-email-template/${eTempId}`,
    status,
    {
      headers: getHeaderOptions(),
    }
  );
}

// export function getStaticPages() {
//   return axios.get(`${API_ENDPOINT}/list-all-static-pages`, {
//     headers: getHeaderOptions(),
//   });
// }

// 01-02-2022

export function getUserStatistics() {
  return axios.get(`${API_UserEndPoint}/app-statistics`, {
    headers: getHeaderOptions(),
  });
}

export function getPostStatistics(params) {
  console.log(params);
  if (params.privacyType === "") delete params.privacyType;
  return axios.get(`${API_ENDPOINT}/post-statistics`, {
    headers: getHeaderOptions(),
    params: params,
  });
}

export function getAudioTemplates(params) {
  return axios.get(`${API_AUDIO}/list-warning-audios`, {
    headers: getHeaderOptions(),
    params: params,
  });
}

export function addRecordingData(data) {
  return axios.post(`${API_AUDIO}/add-warning-audio`, data, {
    headers: getHeaderOptions2(),
  });
}

export function getAudioFetch(id) {
  return axios.get(`${API_AUDIO}/get-warning-audio-details/${id}`, {
    headers: getHeaderOptions(),
  });
}

export function updateAudio(id,data) {
  // console.log(data);
  return axios.put(`${API_AUDIO}/update-warning-audio-details/${id}`, data, {
    headers: getHeaderOptions2(),
  });
}

export function getTemplates(params) {
  return axios.get(`${API_CMS}/list-templates`, {
    headers: getHeaderOptions(),
    params: params,
  });
}

export function setSmsTemplateStatus(sTempId, status) {
  return axios.patch(
    `${API_ENDPOINT}/active-deactive-sms-template/${sTempId}`,
    status,
    {
      headers: getHeaderOptions(),
    }
  );
}

export function getExperiancebyId(id) {
  return axios.get(`${API_ENDPOINT}/get-experience-details-by-admin/${id}`);
}

export function getMyProfile() {
  return axios.get(`${API_User}/get-my-profile`, {
    headers: getHeaderOptions(),
  });
}

export function updateMyProfile(data) {
  // console.log(data);
  return axios.put(`${API_User}/update-profile`, data, {
    headers: getHeaderOptions(),
  });
}

export function changePassword(data) {
  // console.log(data);
  return axios.put(`${API_ENDPOINT}/change-password`, data, {
    headers: getHeaderOptions(),
  });
}

export function updateStaticPage(page_id, data) {
  return axios.patch(`${API_CMS}/update-static-page/${page_id}`, data, {
    headers: getHeaderOptions(),
  });
}

// 10-02-2022

export function getFlaggedReportData(id) {
  return axios.get(`${API_ENDPOINT}/list-report-data/${id}`, {
    headers: getHeaderOptions(),
  });
}

// 11-02-2022

export function updateEmailTemplate(eTempid, data) {
  return axios.patch(`${API_CMS}/update-email-template/${eTempid}`, data, {
    headers: getHeaderOptions(),
  });
}

// 14-02-2022

export function getStaticPageData(id) {
  return axios.get(`${API_CMS}/get-static-page-data/${id}`, {
    headers: getHeaderOptions(),
  });
}

// 16-02-2022

export function updateSmsTemplate(sTempid, data) {
  return axios.patch(`${API_CMS}/update-sms-template/${sTempid}`, data, {
    headers: getHeaderOptions(),
  });
}


//Emergency Contact => API_EmergencyContact
export function getEmergencyContact(params) {
  return axios.get(`${API_EmergencyContact}/admin/list-emergency-contacts-data`, {
    headers: getHeaderOptions(),
    params: params,
  });
}

export function exportEmergencyContact(params) {
  console.log("here params emcontact",params)
  return axios.get(`${API_EmergencyContact}/admin/export-emergency-contact-data`, {
    headers: getHeaderOptions(),
    params: params,
  });
}

//Panic events => API_PanicEvents
export function getPanicEvents(params) {
  return axios.get(`${API_PanicEvents}/admin/list-panic-events`, {
    headers: getHeaderOptions(),
    params: params,
  });
}

export function exportPanicEvents(params) {
  return axios.get(`${API_PanicEvents}/admin/export-panic-event-data`, {
    headers: getHeaderOptions(),
    params: params,
  });
}

export function deleteRecordingApi(params) {
  return axios.delete(`${API_AUDIO}/delete-warning-audio/${params}`, {
    headers: getHeaderOptions(),
  });
}

// API_PanicEvents
// "http://52.14.207.41:6056/v1/alarm/get-panic-event-location-data
export function getApiHeatMap() {
  return axios.get(`${API_PanicEvents}/get-panic-event-loaction-data`, {
    headers: getHeaderOptions(),
  });
}

//Device Management events => API_DeviceInventory
export function getDeviceList(params) {
  return axios.get(`${API_DeviceInventory}`, {
    headers: getHeaderOptions(),
    params: params,
  });
}

export function updateDeviceStatus(id) {
  return axios.patch(`${API_DeviceInventory}/active-inactive-device-inventory/${id}`,  {}, {
    headers: getHeaderOptions(),
  });
}

export function deleteDeviceInventory(id) {
  return axios.delete(`${API_DeviceInventory}/${id}`, {
    headers: getHeaderOptions(),
  });
}

export function addDeviceInInventory(params) {
  return axios.post(`${API_DeviceInventory}/device-inventory`, params, {
    headers: getHeaderOptions(),
  });
}

export function updateDeviceInInventory(id, data) {
  return axios.put(`${API_DeviceInventory}/device-inventory/${id}`, data, {
    headers: getHeaderOptions(),
  });
}

export function getDeviceDetailsById(id) {
  return axios.get(`${API_DeviceInventory}/${id}`, {
    headers: getHeaderOptions(),
  });
}

export function exportDeviceDataApi(params) {
  return axios.get(`${API_DeviceInventory}/export`, {
    headers: getHeaderOptions(),
    params: params,
  });
}

export function importDeviceDataApi(params) {
  return axios.post(`${API_DeviceInventory}/import`, params, {
    headers: getHeaderOptions(),
  });
}

//Subscription
export function getSubscriptionList(params) {
  return axios.get(`${API_Subscription}/admin/users`, {
    headers: getHeaderOptions(),
    params: params,
  });
}

export function getSubscriptionDetails(params) {
  return axios.get(`${API_Subscription}/admin/history`, {
    headers: getHeaderOptions(),
    params: params,
  });
}