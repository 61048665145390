import AlternativeHeader from "components/Headers/AlternativeHeader";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import { updateMyProfile } from "services/services";
import { getMyProfile } from "services/services";

// import PhoneInput from "react-phone-input-2";

// import "react-phone-input-2/lib/bootstrap.css";

const EditProfile = () => {
  const [disabled, setDisabled] = useState(false);
  const [errors, setErrors] = useState(false);
  const [fullName, setfullName] = useState("");
  const [fullNameState, setfullNameState] = useState(null);
  // const [phone, setPhone] = useState("");
  // const [phoneState, setphoneState] = useState(null);
  const [emailId, setEmailId] = useState("");
  // const [profilePic, setProfilePic] = useState("");
  // const [profilePicURL, setProfilePicURL] = useState("");
  // const [profilePicName, setProfilePicName] = useState("");
  const history = useHistory();

  useEffect(() => {
    getProfileDetails();
  }, []);

  const getProfileDetails = async () => {
    try {
      let res = await getMyProfile();
      console.log("data", res);
      if (res && res.status === 200) {
        setfullName(res.data.data.fullName);
        // setPhone(res.data.data.phoneNumber);
        setEmailId(res.data.data.email);
        // setProfilePic(res.data.data.profilePic);
        // setProfilePicURL(res.data.data.profilePic);
        // setProfilePicName(res.data.data.profilePic);
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const onCancel = () => {
    let path = `/admin/myprofile`;
    history.push(path);
  };

  // const onChangeProfile = (e) => {
  //   setProfilePic(e.target.files[0]);
  //   setProfilePicName(e.target.files[0].name);
  //   setProfilePicURL(URL.createObjectURL(e.target.files[0]));
  // };

  const validateCustomStylesForm = () => {
    if (fullName === "") {
      setfullNameState("invalid");
    }
    if (fullName === "") {
        setErrors(true);
      }
  };

  const handelSubmit = async (e) => {
    e.preventDefault();
    if (!errors) {
      setDisabled(true);
      // debugger;
      let data = new FormData();
      data.append("full_name", fullName);
      data.append("email", emailId);
      // data.append("phone_no", phone);
      data.append("country_code", 93);
      // data.append("profile_pic", profilePic);
      // console.log("here data ",data)
      try {
        let res = await updateMyProfile(data);
        if (res && res.status === 200) {
          toast.success("Details updated successfully.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          setTimeout(() => {
            setDisabled(false);
            history.replace({ pathname: "/admin/myprofile" });
          }, 500);
        }
      } catch (err) {
        setDisabled(true);
        if (err.response.status !== 500) {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        } else {
          toast.error("Oops something went wrong...", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      }
    }
  };

  return (
    <>
      <AlternativeHeader
        parentComponent="My Profile"
        pageTitle="Edit Profile"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Edit Profile</h3>
              </CardHeader>
              <CardBody>
                <Form
                  className="needs-validation"
                  noValidate
                  onSubmit={handelSubmit}
                >
                  <div className="form-row">
                    <Col className="mb-3" md="6">
                      <label
                        className="form-control-label"
                        htmlFor="validationCustom01"
                      >
                        Full name
                      </label>
                      <Input
                        id="validationCustom01"
                        placeholder="First name"
                        type="text"
                        valid={fullNameState === "valid"}
                        invalid={fullNameState === "invalid"}
                        name="fullName"
                        onChange={(e) => {
                          setfullName(e.target.value);
                          if (e.target.value === "") {
                            setfullNameState("invalid");
                          } else {
                            setfullNameState("valid");
                          }
                        }}
                        value={fullName}
                      />
                    </Col>

                    <Col className="mb-3" md="6">
                      <label
                        className="form-control-label"
                        htmlFor="validationCustom03"
                      >
                        Email Id
                      </label>
                      <Input
                        id="validationCustom03"
                        placeholder="Email Id"
                        name="email"
                        type="email"
                        value={emailId}
                        disabled
                      />
                      <div className="invalid-feedback">
                        Please Enter valid Email Id.
                      </div>
                    </Col>

                    {/* <Col className="mb-3" md="6">
                      <label
                        className="form-control-label"
                        htmlFor="validationCustom02"
                      >
                        Phone Number
                      </label>
                      <PhoneInput
                        id="validationCustom02"
                        placeholder="Phone Number"
                        type="number"
                        valid={phoneState === "valid"}
                        invalid={phoneState === "invalid"}
                        name="phone"
                        inputStyle={{width:'100%'}}
                        onChange={
                            setPhone
                          }
                        value={phone}
                      />
                    </Col> */}
                    {/* 
<Col className="mb-3" md="6">
                    <label
                        className="form-control-label"
                        htmlFor="validationCustom01"
                      >
                        Contact Number
                      </label>
                    <PhoneInput
                      id="validationCustom01"
                      country={'in'}
                      inputStyle={{width:'100%'}}
                      name="phone"
                      onChange={
                        setPhone
                      }
                      value={phone}
                    />
                    </Col> */}

                    {/* <Col className="mb-3" md="4">
                      <div className="custom_file_upload">
                        <label
                          className="form-control-label"
                          htmlFor="validationCustom07"
                        >
                          Profile
                        </label>
                        <Input
                          className="custom_file_input"
                          id="validationCustom07"
                          type="file"
                          onChange={(e) => {
                            onChangeProfile(e);
                          }}
                        />
                        <Input
                          id="validationCustom07"
                          placeholder="Profile"
                          type="text"
                          value={profilePicName}
                        />
                        <div className="invalid-feedback">
                          Please Enter valid Profile image.
                        </div>
                      </div>
                    </Col> */}
                    {/* <Col className="mb-3" md="4">
                      <label
                        className="form-control-label"
                        htmlFor="validationCustom07"
                      >
                        Profile Preview
                      </label>
                      <div className="img_cls">
                        <img
                          className="rounded-circle imageFitCover"
                          height="140px"
                          width="140px"
                          src={profilePicURL}
                          alt="Profile"
                        />
                      </div>
                    </Col> */}
                    <Col md="4" className="text-right my-3 align-self-center">
                      <Button
                        color="secondary"
                        type="button"
                        onClick={onCancel}
                      >
                        Cancel
                      </Button>
                      <Button
                        color="primary"
                        type="submit"
                        onClick={validateCustomStylesForm}
                      >
                        {disabled && (
                          <Spinner
                            as="span"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}{" "}
                        Update
                      </Button>
                    </Col>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default EditProfile;
