import React, { useState } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  FormFeedback,
  Spinner,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import { validateEmail } from "shared/validation";
import { toast } from "react-toastify";
import { forgotPassword } from "services/services";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

const ForgotPassword = () => {
  const [focusedEmail, setfocusedEmail] = useState(false);
  const [errors, setErrors] = useState();
  const [emailId, setEmailId] = useState();
  const [disabled, setDisabled] = useState(false);
  // const [clickTimer, setClickTimer] = useState(false);
  let history = useHistory();

  var regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

  const handleChange = (e) => {
    let error;
    if (e.target.name === "email") {
      setEmailId(e.target.value.trim().replace(regex, ''));
      error = validateEmail(e.target.value);
      setErrors(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);
    const params = { email: emailId };
    try {
      if (!errors) {
        let response = await forgotPassword(params);
        if (response && response.status === 200) {
          toast.success(response.data.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          setTimeout(() => {
            history.push("/auth/login");
          }, 1000);
        }
      }
    } catch (err) {
      setDisabled(true);
      if (err && err.response && err.response.status !== 500) {
        toast.error(err.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } else {
        toast.error("Oops something went wrong...", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    }
    setTimeout(() => {
      setDisabled(false);
    }, 500);
  };

  return (
    <>
      <AuthHeader title="" />
      {/* // lead="...!!" */}
      <Container className="mt--9">
        <Row className="justify-content-center">
          <Col lg="6" md="8" className="mt--8">
            <h1 className="text-white text-center pb-4">Forgot Password!</h1>
            <Card className="bg-secondary border-0 mb-0">
              <CardHeader className="bg-transparent py-4">
                <div className="btn-wrapper text-center">
                  {/* <h2>Find Me Now</h2> */}
                  <img
                    className="login_logo"
                    alt="Logo"
                    style={{height: "126px"}}
                    src={require("assets/img/brand/Fevicon.png").default}
                  />
                </div>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-3">
                <div className="text-left text-muted mb-4">
                  <small>
                    Please enter an email address, you'd like your password
                    reset information sent to
                  </small>
                </div>
                <Form role="form" onSubmit={handleSubmit}>
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: focusedEmail,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="email"
                        placeholder="Email"
                        type="text"
                        onFocus={() => setfocusedEmail(true)}
                        onBlur={() => setfocusedEmail(true)}
                        onChange={handleChange}
                        value={emailId || ""}
                        invalid={errors && errors.email}
                      />
                      <FormFeedback tooltip>
                        {errors && errors.email}
                      </FormFeedback>
                    </InputGroup>
                  </FormGroup>

                  <div className="text-center">
                  <Button className="my-4" color="info" type="button">
                      <Link to='/'>Back</Link>
                    </Button>

                    <Button className="my-4" color="info" type="submit" >
                      {disabled && (
                        <Spinner
                          as="span"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}{" "}
                      Submit
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ForgotPassword;
