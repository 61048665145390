import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Container, Row, Col, FormFeedback, Spinner } from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import { toast } from "react-toastify";
import { validatePassword } from "shared/validation";
import { resetPassword } from "services/services";
import { useHistory } from "react-router";

const ResetPassword = (props) => {
    const [focusedPassword, setfocusedPassword] = useState(false);
    const [focusedConfirmPassword, setfocusedConfirmPassword] = useState(false);
    const [errors, setErrors] = useState();
    const [token, setToken] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [passIcon, setPassIcon] = useState("fa fa-eye");
    const [passType, setPassType] = useState("password");
    const [passIconCnf, setPassIconCnf] = useState("fa fa-eye");
    const [passTypeCnf, setPassTypeCnf] = useState("password");
    const [disabled, setDisabled] = useState(false);
    let history = useHistory();

    useEffect(() => {
        const tok = props.location.pathname.substring(props.location.pathname.lastIndexOf('/') + 1)
        setToken(tok);
    }, []);

    const handleChange = (e) => {
        let error = validatePassword(e.target.value);
        if (e.target.name === 'password') {
            setPassword(e.target.value)
            setErrors(error);
        } else {
            setConfirmPassword(e.target.value)
            setErrors(error);
        }
    }

    const showHide = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (passType === "text") {
            setPassType("password")
            setPassIcon("fa fa-eye")
        } else {
            setPassType("text")
            setPassIcon("fa fa-eye-slash")
        }
    }

    const cnfShowHide = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (passTypeCnf === "text") {
            setPassTypeCnf("password")
            setPassIconCnf("fa fa-eye")
        } else {
            setPassTypeCnf("text")
            setPassIconCnf("fa fa-eye-slash")
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setDisabled(true);

        if(errors){
            console.log("err",errors.password)
            toast.error(errors.password, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            })
        }else{
            try {
                if (!errors) {
                    const creds = { new_password: password, confirm_password: confirmPassword }
                    let response = await resetPassword(creds, token);
                    if (response && response.status === 200) {
                        toast.success(response.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000
                        });
                        setTimeout(() => {
                            history.replace("/auth/login")
                        }, 1000);
                    }
                }
            } catch (err) {
                if (err && err.response && err.response.status !== 500) {
                    toast.error(err.response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000
                    });
                } else {
                    toast.error('Oops something went wrong...', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000
                    });
                }
            }
        }

       
        setTimeout(() => {
            setDisabled(false);
        }, 500);
    }

    return (
        <>
            <AuthHeader title="" />
            {/* // lead="...!!" */}
            <Container className="mt--9">
                <Row className="justify-content-center">
                    <Col lg="6" md="8" className="mt--8">
                        <h1 class="text-white text-center pb-4">Reset Password!</h1>
                        <Card className="bg-secondary border-0 mb-0">
                            <CardHeader className="bg-transparent py-4">
                                <div className="btn-wrapper text-center">
                                    <img className="login_logo" alt="Logo" src={require("assets/img/brand/Fevicon.png").default} width="114px" height="117px"/>
                                </div>
                            </CardHeader>
                            <CardBody className="px-lg-5 py-lg-3">
                                <div className="text-center text-muted mb-4">
                                    <small>Generate New Password...</small>
                                </div>
                                <Form role="form" onSubmit={handleSubmit}>
                                    <FormGroup
                                        className={classnames("mb-3", {
                                            focused: focusedPassword,
                                        })}
                                    >
                                        <InputGroup className="input-group-merge input-group-alternative position-relative password-field">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-lock-circle-open" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                name="password"
                                                placeholder="New Password"
                                                type={passType}
                                                onFocus={() => setfocusedPassword(true)}
                                                onBlur={() => setfocusedPassword(true)}
                                                onChange={handleChange}
                                                value={password || ''}
                                                invalid={errors && errors.new_password}
                                            />
                                            <div className="showPassIcon" onClick={showHide}><i className={passIcon}></i></div>
                                            <FormFeedback tooltip>{errors && errors.new_password}</FormFeedback>
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup className={classnames("mb-3", { focused: focusedConfirmPassword, })}>
                                        <InputGroup className="input-group-merge input-group-alternative position-relative password-field">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-lock-circle-open" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                name="cPassword"
                                                placeholder="Confirm Password"
                                                type={passTypeCnf}
                                                onFocus={() => setfocusedConfirmPassword(true)}
                                                onBlur={() => setfocusedConfirmPassword(true)}
                                                onChange={handleChange}
                                                value={confirmPassword || ''}
                                                invalid={errors && errors.confirm_password}
                                            />
                                             <div className="showPassIcon" onClick={cnfShowHide}><i className={passIconCnf}></i></div>
                                            <FormFeedback tooltip>{errors && errors.confirm_password}</FormFeedback>
                                        </InputGroup>
                                    </FormGroup>
                                    <div className="text-center">
                                        <Button className="my-4" color="info" type="submit">
                                            {disabled && <Spinner as="span" size="sm" role="status" aria-hidden="true" />}{' '} Reset
                                        </Button>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ResetPassword;
