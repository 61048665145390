import AlternativeHeader from "components/Headers/AlternativeHeader";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import { getAudioTemplates, addRecordingData } from "services/services";

import DatePicker from "react-datepicker";

import moment from "moment";

export default function AddRecording() {

  const history = useHistory()

  const [disabled, setDisabled] = useState(false);
  const [errors, setErrors] = useState(false);
  const [errorRec, setErrorRec] = useState(false);

  const [recordingName, setrecordingName] = useState("");
  const [recordingNameState, setrecordingNameState] = useState(null);

  const [startDate, setStartDate] = useState("");
  const [dateData, setDateData] = useState("");
  const [responseData, setResponseData] = useState([]);

  const [timeError, setTimeError] = useState(false);

  const [loading, setLoading] = useState(false);

  const [languageData, setLanguageData] = useState("english");

  const [timeErrorResponse, setTimeErrorResponse] = useState(false);

  const [file, setFile] = useState([]);

  const validateCustomStylesForm = () => {
    if (recordingName === "") {
      setrecordingNameState("invalid");
    }
    if (recordingName === "") {
      setErrors(true);
    } else {
      setErrors(false);
    }
  };

  //here audio duration validation

  const getVideoDuration = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const media = new Audio(reader.result);
      media.onloadedmetadata = () => resolve(media.duration);
    };
    reader.readAsDataURL(file);
    reader.onerror = (error) => reject(error);
  });
    

  const handleFileSelected = async(e) => {
    setErrorRec("");
    const duration = await getVideoDuration(e.target.files[0]);
    console.log("duration",duration)
    if(duration > 30){
      setErrorRec("Warning Audio should be less than 30 seconds.");
      setFile([]);
    }else if(duration < 30){
      const filesx = Array.from(e.target.files);
      setFile(filesx);
    }
  };
  


  const handelCreate = async (e) => {
    e.preventDefault();
    setDisabled(true);
    setLoading(true);
    if (file.length === 0) {
      setErrorRec("Enter Recording Audio");
      setLoading(false);
    } else {
      setErrorRec(null);
    }

    if (timeError === true) {
      if (errors === false && file.length === 1) {
        console.log(recordingName, file);
        let data = new FormData();
        data.append("audio_file", file[0]);
        data.append("language", languageData);
        data.append("trigger_time", dateData);
        data.append("warning_audio_name", recordingName);
        try {
          let res = await addRecordingData(data);
          console.log("res ", res);
          if (res && res.status === 200) {
            setLoading(false);
            toast.success("Sucessfully Added", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            history.push("/admin/recording")
          }
        } catch (err) {
          setLoading(false);
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      }
    } else if (timeError === false) {
      setLoading(false);
      toast.error("Two warning recordings should have minimum gap of 5 minutes", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  useEffect(() => {
    getAllEmailTemplates();
    setLoading(true);
  }, []);

  function timeTigger(date) {

    if(timeErrorResponse === false){
      const hms = moment(date).format("HH:mm:ss");
      setDateData(hms);
      const [hours, minutes] = hms.split(":");
      const totalSeconds = +hours * 60 * 60 + +minutes * 60;

      // let arrTime=['05:40','01:01']

      for (let i = 0; i < responseData?.length; i++) {
        const hmsArr = responseData[i]?.triggerTime;
        const [hoursarr, minutesarr] = hmsArr.split(":");
        const totalSecondsarr = +hoursarr * 60 * 60 + +minutesarr * 60;

        if((totalSeconds > (totalSecondsarr - 300)) && (totalSeconds < (totalSecondsarr +300))){
          // console.log("error data")
          setTimeError(false)
        }else{
          // console.log("upload data")
          setTimeError(true)
        }
    }
    }else if(timeErrorResponse === true){
      const hms = moment(date).format("HH:mm:ss");
      setDateData(hms);
      setTimeError(true)
    }
    
    
  }

  const getAllEmailTemplates = async () => {
    setLoading(true);
    try {
      let response = await getAudioTemplates({ template_type: 1 });
      if (response && response.status === 200) {
        console.log("check res",response)
        setLoading(false);
        setResponseData(response?.data?.data);
      }
      setTimeErrorResponse(false)
    } catch (err) {
      setTimeErrorResponse(true)
      setLoading(false);
    }
  };


  return (
    <>
      <AlternativeHeader
        parentComponent="Warning Audio"
        pageTitle="Add Audio"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Add Audio</h3>
              </CardHeader>
              <CardBody>
                {errors}
                <Form
                  className="needs-validation"
                  noValidate
                  onSubmit={handelCreate}
                >
                  <div className="d-flex justify-content-center">
                    <Col className="mb-3" md="6">
                      <label
                        className="form-control-label"
                        htmlFor="validationCustom01"
                      >
                        Warning Audio Name
                      </label>
                      <Input
                        id="validationCustom01"
                        placeholder="Warning Audio Name"
                        type="text"
                        valid={recordingNameState === "valid"}
                        invalid={recordingNameState === "invalid"}
                        name="recordingName"
                        onChange={(e) => {
                          setrecordingName(e.target.value);
                          if (e.target.value === "") {
                            setrecordingNameState("invalid");
                          } else {
                            setrecordingNameState("valid");
                          }
                        }}
                        value={recordingName}
                      />
                      <div className="invalid-feedback">
                        Please Enter Warning Audio Name.
                      </div>
                    </Col>
                    <Col className="mb-3" md="6">
                      <label
                        className="form-control-label"
                        htmlFor="vid"
                      >
                        Here Select Warning Audio
                      </label>
                      <Input
                        className="custom_file_input"
                        id="vid" 
                        type="file"
                        accept=".mp3"
                        onChange={handleFileSelected}
                      />
                      <p style={{ color: "red", padding: "10px" }}>
                        {errorRec}
                      </p>
                    </Col>
                  </div>

                  <div className="d-flex justify-content-center">
                    <Col className="mb-3" md="6">
                      <label
                        className="form-control-label"
                        htmlFor="validationCustom01"
                      >
                        Trigger Time
                      </label>
                      <DatePicker
                       className="form-control"
                        selected={startDate}
                        onChange={(date) => {
                          setStartDate(date);
                          timeTigger(date);
                        }}
                        timeFormat="HH:mm:ss"
                        timeCaption="time"
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={1}
                        dateFormat="HH:mm:ss"
                        // onClick={timeTigger()}
                      />
                    </Col>
                    <Col className="mb-3" md="6">
                      {/* <label
                        className="form-control-label"
                        htmlFor="validationCustom01"
                      >
                        Language
                      </label>
                      <Input
                        type={"select"}
                        onChange={(e) => setLanguageData(e.target.value)}
                      >
                        <option value={"english"}>English</option>
                        <option value={"hindi"}>Hindi</option>
                      </Input> */}
                    </Col>
                  </div>

                  <div className="d-flex justify-content-left">
                    <Col className="mb-3" md="6">
                      <Button
                        color="primary"
                        type="submit"
                        onClick={validateCustomStylesForm}
                      >
                        {loading && (
                          <Spinner
                            as="span"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}{" "}
                        Submit
                      </Button>
                    </Col>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
