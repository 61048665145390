import React, { useEffect, useState, useRef } from "react";

import {
  getAudioFetch,
} from "services/services";
import { useHistory } from "react-router";

import "./modal.css";

import Slider from '../components/slider/Slider'
import ControlPanel from '../components/controls/ControlPanel'

export default function RecordingCop(props) {

    const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [emailTemplateList, setEmailTemplateList] = useState("");
  const [eTempId, setETempId] = useState("");
  const [alert, setAlert] = useState(null);
  const [templateStatus, setTemplateStatus] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState("");
  const [modalType, setModalType] = useState("");
  const count = 0;
  const history = useHistory();
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    useTableProcessor: false,
    useTableResizer: false,
    buttons: [
      "source",
      "|",
      "bold",
      "strikethrough",
      "underline",
      "italic",
      "|",
      "ul",
      "ol",
      "|",
      "outdent",
      "indent",
      "|",
      "font",
      "fontsize",
      "brush",
      "paragraph",
      "|",
      "table",
      "superscript",
      "subscript",
      "|",
      "align",
      "undo",
      "redo",
      "|",
      "hr",
      "eraser",
      "copyformat",
      "|",
      "symbol",
    ],
  };


  const [percentage, setPercentage] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [songApi, setSongApi] = useState()

  const audioRef = useRef()

  const onChange = (e) => {
    const audio = audioRef.current
    audio.currentTime = (audio.duration / 100) * e.target.value
    setPercentage(e.target.value)
  }

  const play = () => {
    const audio = audioRef.current
    audio.volume = 0.1

    if (!isPlaying) {
      setIsPlaying(true)
      audio.play()
    }

    if (isPlaying) {
      setIsPlaying(false)
      audio.pause()
    }
  }

  const getCurrDuration = (e) => {
    const percent = ((e.currentTarget.currentTime / e.currentTarget.duration) * 100).toFixed(2)
    const time = e.currentTarget.currentTime

    setPercentage(+percent)
    setCurrentTime(time.toFixed(2))
  }

    useEffect(() => {
        audioTemplates();
    }, []);

    const audioTemplates = async () => {
    try {
      let response = await getAudioFetch(props.cmp);
      console.log("audio",response.data.data.audioFile)
      if (response && response.status === 200) {
        setSongApi(response.data.data.audioFile)
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setEmailTemplateList([]);
    }
  };
  return (
    <>
        <Slider percentage={percentage} onChange={onChange} />
              <audio
                ref={audioRef}
                onTimeUpdate={getCurrDuration}
                onLoadedData={(e) => {
                  setDuration(e.currentTarget.duration.toFixed(2))
                }}
                src={songApi}
              ></audio>
              <ControlPanel
                play={play}
                isPlaying={isPlaying}
                duration={duration}
                currentTime={currentTime}
              />
    </>
  )
}
