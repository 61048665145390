import Alternative from "views/pages/dashboards/Alternative.js";
import Lock from "views/pages/examples/Lock.js";
import Login from "views/pages/examples/Login.js";
import Pricing from "views/pages/examples/Pricing.js";
import Profile from "views/pages/examples/Profile.js";
import Register from "views/pages/examples/Register.js";
import RTLSupport from "views/pages/examples/RTLSupport.js";
import Timeline from "views/pages/examples/Timeline.js";
import Customer from "views/pages/management/Customer";
import ForgotPassword from "views/pages/examples/ForgotPassword";
import ResetPassword from "views/pages/examples/ResetPassword";
import CustomerDetails from "views/pages/management/CustomerDetails";
import Experiences from "views/pages/experiences/Experiences";
import Flagged from "views/pages/flagged/Flagged";
import AdminDetails from "views/pages/management/AdminDetails";
import AdminList from "views/pages/management/AdminList";
import EmailTemplate from "views/pages/cms/EmailTemplate";
import SmsTemplate from "views/pages/cms/SmsTemplate";
import StaticPage from "views/pages/cms/StaticPage";
import ViewExperiences from "views/pages/experiences/ViewExperiences";
// import ViewExperiences1 from "views/pages/experiences/ViewExperiences1";
import MyProfile from "views/pages/profile/MyProfile";
import EditProfile from "views/pages/profile/EditProfile";
import ChangePassword from "views/pages/profile/ChangePassword";
import Welcome from "views/pages/cms/EmailTemplates/welcome";
import ViewStaticPage from "views/pages/cms/ViewStaticPage";
import RecordingTemplate from "views/pages/cms/RecordingTemplate";
import AddRecording from "views/pages/cms/AddRecording";
import EmergencyContact from "views/pages/management/EmergencyContact";
import PanicEvent from "views/pages/management/PanicEvent";
import HitMap from "views/HitMap/HitMap";
import Heatmap from "views/Heatmapv2/Heatmap";
import { Heatmapv3 } from "views/Heatmapv3/Heatmapv3";
import DeviceManagement from "views/pages/deviceManagement";
import Subscription from "views/pages/subscription";
import SubscriptionDetails from "views/pages/subscription/SubscriptionDetails";

const routes = [
  {
    // collapse: true,
    path: "/dashboard",
    name: "Dashboard",
    icon: "fas fa-tachometer-alt text-primary",
    component: Alternative,
    layout: "/admin",
  },
  // {
  //   // collapse: true,
  //   path: "/dashboard1",
  //   name: "Dashboard1",
  //   icon: "fas fa-tachometer-alt text-primary",
  //   component: Dashboard,
  //   layout: "/admin",
  // },
  {
    collapse: false,
    name: "User Management",
    icon: "fas fa-users-cog text-danger",
    state: "userManagementCollapse",
    path: "/customer",
    miniName: "CM",
    component: Customer,
    layout: "/admin",

    // views: [
    //   {
    //     path: "/customer",
    //     name: "Customer Management",
    //     miniName: "CM",
    //     component: Customer,
    //     layout: "/admin",
    //   },
    //   // {
    //   //   path: "/customer1",
    //   //   name: "Customer Management New",
    //   //   miniName: "CM",
    //   //   component: Customer1,
    //   //   layout: "/admin",
    //   // },
    //   // {
    //   //   path: "/reactTables",
    //   //   name: "ReactBSTables",
    //   //   miniName: "CM",
    //   //   component: ReactBSTables,
    //   //   layout: "/admin",
    //   // },
    //   // {
    //   //   path: "/tables",
    //   //   name: "Table",
    //   //   miniName: "CM",
    //   //   component: Tables,
    //   //   layout: "/admin",
    //   // },
    //   // {
    //   //   path: "/sorttables",
    //   //   name: "SortTable",
    //   //   miniName: "CM",
    //   //   component: Sortable,
    //   //   layout: "/admin",
    //   // },
    //   {
    //     path: "/adminlist",
    //     name: "Admin Management",
    //     miniName: "AM",
    //     component: AdminList,
    //     layout: "/admin",
    //   },
    // ],
  },
  {
    // collapse: true,
    path: "/emergency-contact",
    name: "Emergency Contact",
    icon: "fas fa-address-book text-primary",
    component: EmergencyContact,
    layout: "/admin",
  },
  {
    // collapse: true,
    path: "/subscription",
    name: "Subscription",
    icon: "fas fa-address-book text-yellow",
    component: Subscription,
    layout: "/admin",
  },
  // <i class="fa-solid fa-fire-extinguisher"></i>
  {
    collapse: true,
    name: "Examples",
    icon: "ni ni-ungroup text-orange",
    state: "examplesCollapse",
    views: [
      {
        path: "/pricing",
        name: "Pricing",
        miniName: "P",
        component: Pricing,
        layout: "/auth",
      },
      {
        path: "/login",
        name: "Login",
        miniName: "L",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/experiance",
        name: "Experiance",
        miniName: "Ex",
        component: ViewExperiences,
        layout: "/auth",
      },
      {
        path: "/myprofile",
        name: "Profile",
        miniName: "mp",
        component: MyProfile,
        layout: "/admin",
      },
      {
        path: "/changepassword",
        name: "Change Password",
        miniName: "cp",
        component: ChangePassword,
        layout: "/admin",
      },
      {
        path: "/editprofile",
        name: "EditProfile",
        miniName: "ep",
        component: EditProfile,
        layout: "/admin",
      },
      {
        path: "/customerdetails",
        name: "CD",
        miniName: "CD",
        component: CustomerDetails,
        layout: "/admin",
      },
      {
        path: "/subscription-details",
        name: "CD",
        miniName: "CD",
        component: SubscriptionDetails,
        layout: "/admin",
      },
      {
        path: "/admindetails",
        name: "CD",
        miniName: "CD",
        component: AdminDetails,
        layout: "/admin",
      },
      {
        path: "/register",
        name: "Register",
        miniName: "R",
        component: Register,
        layout: "/auth",
      },
      {
        path: "/lock",
        name: "Lock",
        miniName: "L",
        component: Lock,
        layout: "/auth",
      },
      {
        path: "/timeline",
        name: "Timeline",
        miniName: "T",
        component: Timeline,
        layout: "/admin",
      },
      {
        path: "/profile",
        name: "Profile",
        miniName: "P",
        component: Profile,
        layout: "/admin",
      },
      {
        path: "/rtl-support",
        name: "RTL Support",
        miniName: "RS",
        component: RTLSupport,
        layout: "/rtl",
      },
      {
        path: "/forgotpassword",
        name: "Forgot Password",
        icon: "ni ni-calendar-grid-58 text-red",
        component: ForgotPassword,
        layout: "/auth",
      },
      {
        path: "/resetpassword",
        name: "Reset Password",
        icon: "ni ni-calendar-grid-58 text-red",
        component: ResetPassword,
        layout: "/auth",
      },
      {
        path: "/emailtemplate",
        name: "Email Template",
        icon: "ni ni-calendar-grid-58 text-red",
        component: Welcome,
        layout: "/admin",
      },
      {
        path: "/staticpage",
        name: "Static Page",
        icon: "ni ni-calendar-grid-58 text-red",
        component: ViewStaticPage,
        layout: "/auth",
      },
    ],
  },
  {
    collapse: true,
    name: "CMS",
    icon: "fas fa-newspaper text-orange",
    state: "cmsCollapse",
    views: [
      {
        path: "/email",
        name: "Email Templates",
        miniName: "E",
        component: EmailTemplate,
        layout: "/admin",
      },
      {
        path: "/sms",
        name: "SMS Templates",
        miniName: "S",
        component: SmsTemplate,
        layout: "/admin",
      },
      {
        path: "/recording",
        name: "Warning Audio",
        miniName: "S",
        component: RecordingTemplate,
        layout: "/admin",
      },
      {
        path: "/static",
        name: "Static Pages",
        miniName: "S",
        component: StaticPage,
        layout: "/admin",
      },
    ],
  },

  {
    // collapse: true,
    path: "/panic-events",
    name: "Panic Events",
    icon: "fas fa-fire-extinguisher text-red",
    component: PanicEvent,
    layout: "/admin",
  },
  // {
  //   // collapse: true,
  //   path: "/heat-map",
  //   name: "Heat Map",
  //   icon: "fas fa-map text-green",
  //   component: HitMap,
  //   layout: "/admin",
  // },
  // {
  //   // collapse: true,
  //   path: "/heat-map",
  //   name: "Heat Map",
  //   icon: "fas fa-map text-green",
  //   component: Heatmap,
  //   layout: "/admin",
  // },
  {
    // collapse: true,
    path: "/device-management",
    name: "Device Management",
    icon: "fas fa-mobile text-blue",
    component: DeviceManagement,
    layout: "/admin",
  },
  {
    // collapse: true,
    path: "/heat-map",
    name: "Heat Map",
    icon: "fas fa-map text-green",
    component: Heatmapv3,
    layout: "/admin",
  },
  // {
  //   // collapse: true,
  //   path: "/heat-mapv2",
  //   name: "Heat Map v2",
  //   icon: "fas fa-map text-green",
  //   component: Heatmap,
  //   layout: "/admin",
  // },
  {
    path: "/addrecording",
    component: AddRecording,
    layout: "/admin",
  },
  // {
  //   path: "/experiences",
  //   name: "Experiences Management",
  //   icon: "fas fa-mail-bulk text-info",
  //   component: Experiences,
  //   layout: "/admin",
  // },
  // {
  //   path: "/flagged",
  //   name: "Flagged Posts",
  //   icon: "fas fa-flag text-danger",
  //   component: Flagged,
  //   layout: "/admin",
  // },
];

export default routes;
