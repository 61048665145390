import React, { useEffect, useState } from "react";
import AlternativeHeader from "components/Headers/AlternativeHeader";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Spinner,
  Modal,
} from "reactstrap";
import DataTable, { createTheme } from "react-data-table-component";
import {
  getFlaggedPosts,
  flaggedPostAction,
  getTemplates,
  setSmsTemplateStatus,
  updateSmsTemplate,
} from "services/services";
import { useHistory } from "react-router";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { toast } from "react-toastify";

const SmsTemplate = () => {
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [smsTemplateList, setSmsTemplateList] = useState();
  const [sTempId, setSTempId] = useState("");
  const [alert, setAlert] = useState(null);
  const [templateStatus, setTemplateStatus] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState("");
  const [modalType, setModalType] = useState();
  const toggle = () => setModal(!modal);
  const [content, setContent] = useState("");
  const count = 0;
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    getAllSmsTemplates();
  }, []);

  const onAction = async (type) => {
    if (type === 1) {
      let data = { content: modalData };
      toggle();
      try {
        let res = await updateSmsTemplate(sTempId, data);
        if (res && res.status === 200) {
          toast.success("Updated Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          setLoading(true);
          setTimeout(() => {
            getAllSmsTemplates();
          }, 1000);
        }
      } catch (error) {
        if (error && error.response && error.response.status !== 500) {
          console.log(error);
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        } else {
          toast.error("Oops something went wrong...", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      }
    } else {
      toggle();
    }
  };

  const columns = [
    {
      name: "Sr No.",
      center: true,
      width: "100px",
      selector: (row) => row?.sTempId,
      sortable: true,
      // cell: (row, count) => {
      //   return ++count;
      // },
    },
    {
      name: "Template Name",
      selector: (row) => row?.sTempName,
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      center: true,
      cell: (row) => {
        return (
          <div className="d-flex justify-content-center text-center m-1">
            <>
              <Button
                className="mr-2 btn-info btn-sm"
                // onClick={() => onAction(row?.sTempId, 1)}
                onClick={() => displayModel(row?.sTempId, row?.content, "view")}
              >
                View
              </Button>
              <Button
                className="mr-2 btn-info btn-sm"
                onClick={() => displayModel(row?.sTempId, row?.content, "edit")}
              >
                Edit
              </Button>
              {/* <div>
                {row.isActive === true ? (
                  <label className="custom-toggle custom-toggle-success mr-1">
                    <input
                      defaultChecked
                      type="checkbox"
                      onChange={() =>
                        deactivateTemplate(row.sTempId, row.isActive)
                      }
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                ) : (
                  <label className="custom-toggle custom-toggle-success mr-1">
                    <input
                      type="checkbox"
                      onChange={() =>
                        activateTemplate(row.sTempId, row.isActive)
                      }
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                )}
              </div> */}
            </>
          </div>
        );
      },
    },
  ];

  const getAllSmsTemplates = async (params) => {
    try {
      let response = await getTemplates({ template_type: 2 });
      console.log(response.data.data);
      if (response && response.status === 200) {
        setSmsTemplateList(response.data.data);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setSmsTemplateList([]);
    }
  };

  // const activateTemplate = (sTempId, isActive) => {
  //   // setShowAlert(true);
  //   setSTempId(sTempId);
  //   setTemplateStatus(true);
  //   setAlert(
  //     <ReactBSAlert
  //       warning
  //       style={{ display: "block", marginTop: "-100px" }}
  //       title="Are you sure?"
  //       onConfirm={() => onConfirmation(sTempId, isActive)}
  //       showCancel
  //       onCancel={() => onCancel()}
  //       confirmBtnBsStyle="secondary"
  //       confirmBtnText="Yes"
  //       cancelBtnBsStyle="danger"
  //       cancelBtnText="Cancel"
  //     >
  //       {isActive === true
  //         ? "You want to deactivate this Template!"
  //         : "You want to activate this Template!"}
  //     </ReactBSAlert>
  //   );
  // };

  // const deactivateTemplate = (sTempId, isActive) => {
  //   // setShowAlert(true);
  //   setSTempId(sTempId);
  //   setTemplateStatus(false);
  //   setAlert(
  //     <ReactBSAlert
  //       warning
  //       style={{ display: "block", marginTop: "-100px" }}
  //       title="Are you sure?"
  //       onConfirm={() => onConfirmation(sTempId, isActive)}
  //       showCancel
  //       onCancel={() => onCancel()}
  //       confirmBtnBsStyle="secondary"
  //       confirmBtnText="Yes"
  //       cancelBtnBsStyle="danger"
  //       cancelBtnText="Cancel"
  //     >
  //       {isActive === true
  //         ? "You want to deactivate this Template!"
  //         : "You want to activate this Template!"}
  //     </ReactBSAlert>
  //   );
  // };

  const onCancel = () => {
    setAlert(null);
    setLoading(true);
    getAllSmsTemplates();
  };

  const onConfirmation = async (sTempId, isActive) => {
    let templateStatus = isActive === true ? false : true;
    let data = { is_active: templateStatus };

    let success_msg =
      isActive === true
        ? "Template Deactivated Successfully."
        : "Template Activated Successfully.";

    try {
      let res = await setSmsTemplateStatus(sTempId, data);
      if (res && res.status === 200) {
        toast.success(success_msg, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setLoading(true);
        setTimeout(() => {
          getAllSmsTemplates();
        }, 2000);
      }
    } catch (error) {
      if (error && error.response && error.response.status !== 500) {
        console.log(error);
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } else {
        toast.error("Oops something went wrong...", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    }

    setAlert(null);
  };

  const displayModel = async (sTempId, content, type) => {
    setSTempId(sTempId);
    setModalData(content);
    // console.log(content);
    // setContent(content);
    setModalType(type);
    toggle();
  };

  return (
    <>
      {alert}
      <AlternativeHeader parentComponent="CMS" pageTitle="SMS Template" />

      <Modal
        className="modal-dialog-centered"
        isOpen={modal}
        toggle={toggle}
        modalTransition={{ timeout: 100 }}
        scrollable={true}
        // fullscreen={true}
        // size="xl"
        className=""
        // dialogClassName="myModalStyle"
        // style={{ width: "90%", maxWidth: "none" }}
      >
        <div className="modal-header p-2">
          {/* <h6 className="modal-title" id="modal-title-default">
            {modalData?.experience_description}
          </h6> */}
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggle}
          >
            <span aria-hidden={true} style={{ fontSize: "25px" }}>
              ×
            </span>
          </button>
        </div>
        <div className="modal-body p-2">
          <div className="justify-content-center text-center p-2" id="content">
            {modalType === "view" ? (
              <p>{modalData}</p>
            ) : (
              <>
                <Input
                  type="textarea"
                  value={modalData}
                  rows={10}
                  onChange={(e) => {
                    setModalData(e.target.value);
                  }}
                />
                <div className="m-2">
                  <Button
                    className="m-2 btn btn-info"
                    onClick={() => onAction(1)}
                  >
                    Update
                  </Button>
                  <Button
                    className="m-2 btn btn-danger "
                    onClick={() => onAction(0)}
                  >
                    Cancel
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>

      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">SMS Templates</h3>
              </CardHeader>
              <CardBody>
                <DataTable
                  columns={columns}
                  data={smsTemplateList}
                  progressPending={loading}
                  progressComponent={
                    <Spinner
                      as="span"
                      size="lg"
                      role="status"
                      aria-hidden="true"
                    />
                  }
                  //   theme="solarized"
                  striped
                  //   customStyles={customStyles}
                />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default SmsTemplate;
