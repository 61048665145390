import React, { useEffect, useState } from "react";
import AlternativeHeader from "components/Headers/AlternativeHeader";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Spinner,
} from "reactstrap";
import DataTable from "react-data-table-component";
import {
  getEmergencyContact,
  updateUserStatus,
  exportEmergencyContact,
} from "services/services";
import { useHistory } from "react-router";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { toast } from "react-toastify";
import { CSVLink, CSVDownload } from "react-csv";
import { getSubscriptionList } from "services/services";
import moment from "moment";
import { getSubscriptionDetails } from "services/services";
import "./styles.css";

const SubscriptionDetails = (props) => {
  const [perPage, setPerPage] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [subscriptionList, setSubscriptionList] = useState("");

  const [hostData, setHostData] = useState({
    fullName: "",
    userName: "",
    email: "",
    phoneNumber: "",
  });

  const history = useHistory();

  useEffect(() => {
    const userId = props.location.pathname.substring(
      props.location.pathname.lastIndexOf("/") + 1
    );
    if (userId !== "add") {
      setUserId(userId);
    }
  }, []);

  useEffect(() => {
    let params = {
      limit: perPage,
      page_no: pageNo,
    };

    const userId = props.location.pathname.substring(
      props.location.pathname.lastIndexOf("/") + 1
    );
    if (userId !== "add") {
      setUserId(userId);
      params["user_id"] = userId;
      getSubscriptionApiCall(params);
    }

    // let dataContact = { active_status: selectStatus, user_id: userId };
    // exportContact(dataContact);
  }, []);

  const statusValue = (compareValue) => {
    const currentDate = new Date();

    const subDateObject = new Date(compareValue);

    if (subDateObject > currentDate) {
      return true;
    } else {
      return false;
    }
  };

  let count = 1;

  const columns = [
    {
      name: "S. No.",
      selector: (row, index) => (pageNo - 1) * perPage + index + 1,
      sortable: true,
    },

    {
      name: "Plan Period",
      selector: (row) =>
        row?.subscriptionPlanId === "ResQ_Monthly_Subscriptions"
          ? "Monthly"
          : "Yearly",
      sortable: true,
    },
    {
      name: "Purchased on",
      selector: (row) => moment(row?.createdAt).format("M/DD/YYYY"),
      sortable: true,
    },
    {
      name: "Expired on",
      selector: (row) => moment(row?.subscriptionEndDate).format("M/DD/YYYY"),
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => "N/A",
      sortable: true,
    },
    {
      name: "Payment Status",
      selector: (row) => "N/A",
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row?.isActive,
      sortable: true,
      cell: (row) => {
        return (
          <div>
            {statusValue(row?.subscriptionEndDate) ? (
              <span
                class="badge badge-success"
                style={{
                  color: "green",
                  fontWeight: "bold",
                  fontSize: "11px",
                  padding: "9px",
                }}
              >
                Ongoing
              </span>
            ) : (
              <span
                class="badge badge-danger"
                style={{
                  color: "red",
                  fontWeight: "bold",
                  fontSize: "11px",
                  padding: "9px",
                }}
              >
                Expired
              </span>
            )}
          </div>
        );
      },
    },
  ];

  const getSubscriptionApiCall = async (params) => {
    setLoading(true);
    try {
      let response = await getSubscriptionDetails(params);
      console.log("search ", response);
      if (response && response.status === 200) {
        setHostData({
          fullName: response?.data?.hostData?.fullName,
          userName: response?.data?.hostData?.userName,
          email: response?.data?.hostData?.email,
          phoneNumber: response?.data?.hostData?.phoneNumber ? `${response?.data?.hostData?.countryCode} ${response?.data?.hostData?.phoneNumber}` : "",
        });
        setSubscriptionList(response?.data?.transactionData);
        setTotalRows(response?.data?.totalResult);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setSubscriptionList();
      setHostData({
        fullName: "",
        userName: "",
        email: "",
        phoneNumber: "",
      });
      setTotalRows(0);
    }
  };

  //   const onSearch = async (e) => {
  //     console.log("here change ", e.target.value);
  //     setUserId(e.target.value);
  //     let dataContact = { active_status: selectStatus, user_id: e.target.value };
  //     exportContact(dataContact);
  //     if (e.target.value.length > 2) {
  //       let params = {
  //         limit: perPage,
  //         page_no: pageNo,
  //         user_id: e.target.value,
  //         active_status: selectStatus,
  //       };
  //       getSubscriptionApiCall(params);
  //     } else {
  //       let params = {
  //         limit: perPage,
  //         page_no: pageNo,
  //         user_id: e.target.value,
  //         active_status: selectStatus,
  //       };
  //       getSubscriptionApiCall(params);
  //     }
  //   };

  const handlePageChange = (page) => {
    setLoading(true);
    setPageNo(page);
    let params = {
      limit: perPage,
      page_no: page,
      user_id: userId,
    };
    getSubscriptionApiCall(params);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    let params = {
      limit: newPerPage,
      page_no: page,
      user_id: userId,
    };
    getSubscriptionApiCall(params);
    setPerPage(newPerPage);
  };

  //   const handleSelect = (e) => {
  //     setLoading(true);
  //     console.log("here slect", e.target.value);
  //     let params = {
  //       limit: perPage,
  //       page_no: pageNo,
  //       user_id: userId,
  //       active_status: e.target.value,
  //     };
  //     getSubscriptionApiCall(params);
  //     setSelectStatus(e.target.value);
  //     let dataContact = { active_status: e.target.value, user_id: userId };
  //     exportContact(dataContact);
  //   };

  //   const exportContact = async (dataContact) => {
  //     try {
  //       let response = await exportEmergencyContact(dataContact);
  //       console.log("here res ", response);
  //       if (response && response.status === 200) {
  //         setExportcontactList(response.data);
  //       }
  //     } catch (err) {
  //       setLoading(false);
  //     }
  //   };

  return (
    <>
      <AlternativeHeader pageTitle="Subscription Details" parentComponent="" />

      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Subscription</h3>
              </CardHeader>
              <CardBody>
                <div className="subscrmaaain">
                  <div className="inneersection">
                    <h3>User Details</h3>
                    <div className="subdatasection">
                      <ul>
                        <li>
                          Full Name <span>{hostData?.fullName}</span>
                        </li>
                        <li>
                          Email Address <span>{hostData?.email}</span>
                        </li>
                        <li>
                          User Name <span>{hostData?.userName}</span>
                        </li>
                        <li>
                          Phone number <span>{hostData?.phoneNumber}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <h3 className="mb-0">Transaction Details</h3>
                <DataTable
                  columns={columns}
                  data={subscriptionList}
                  progressPending={loading}
                  progressComponent={
                    <Spinner
                      as="span"
                      size="lg"
                      role="status"
                      aria-hidden="true"
                    />
                  }
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
                  striped
                />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SubscriptionDetails;
