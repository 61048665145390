// reactstrap components
import { Container, Row, Col } from "reactstrap";

function AdminFooter() {
  return (
    <>
      <Container fluid className="footer_container">
        <footer className="footer pt-0">
          <Row className="align-items-center justify-content-lg-between">
            <Col lg="12">
              <div className="copyright text-right text-muted">© {new Date().getFullYear()}{" "}Find Me Now</div>
            </Col>
          </Row>
        </footer>
      </Container>
    </>
  );
}

export default AdminFooter;
