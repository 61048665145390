import AlternativeHeader from 'components/Headers/AlternativeHeader';
import ProfileHeader from 'components/Headers/ProfileHeader';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { getMyProfile } from 'services/services';

const MyProfile = () => {
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [profilePic, setProfilePic] = useState("https://cdn-icons-png.flaticon.com/512/3135/3135715.png");
    const [joiningDate, setJoiningDate] = useState("");
    const history = useHistory();

    useEffect(() => {
        getProfileDetails();
    }, []);

    

    const getProfileDetails = async () => {
        try {
            let res = await getMyProfile();
            console.log("res data", res.data.data.profilePic)
            if (res && res.status === 200) {
                setFullName(res.data.data.fullName);
                setEmail(res.data.data.email);
                if(res.data.data.profilePic === ""){
                    console.log("first")
                }else{
                    // setProfilePic(res.data.data.profilePic);
                }
                setJoiningDate(res.data.data.joinedDate);
            }
        } catch (err) {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
        }
    }

    const onEdit = (e) => {
        let path = `/admin/editprofile`;
        history.push(path);
    }

    const onChangePassword = (e) => {
        let path = `/admin/changepassword`;
        history.push(path);
    }


    return (
        <>
            <AlternativeHeader parentComponent="" pageTitle="My Profile" />
            <Container className="mt--6" fluid>
                <Row className='justify-content-center'>
                    <Col className="order-xl-2" md="12">
                        <Card className="card-profile">
                            <ProfileHeader name={fullName} onEdit={(e) => onEdit(e)} onChangePassword={(e) => onChangePassword(e)} />
                            <Row className="justify-content-center">
                                <Col className="order-lg-2" lg="3">
                                    <div className="card-profile-image">
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                            <img className="rounded-circle" src={profilePic} alt="profile" />
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                            <CardBody className='pt-7'>
                                <div className="text-center">
                                    <h3 className="h2 mb-2">{fullName}</h3>
                                    <h4 className="h4 font-weight-300">{email}</h4>
                                    <h4 className="h4 font-weight-300 pb-3"><strong>Joining Date:</strong> {new Date(joiningDate).toLocaleDateString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' })}</h4>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default MyProfile