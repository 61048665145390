import React, { useEffect, useState } from "react";
import AlternativeHeader from "components/Headers/AlternativeHeader";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Spinner,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { getUserList, updateUserStatus } from "services/services";
import { useHistory } from "react-router";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import { exportUser } from "services/services";

const Customer = (props) => {
  const [perPage, setPerPage] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [userList, setUserList] = useState("");
  const [userId, setUserId] = useState("");
  const [userStatus, setUserStatus] = useState(false);
  const [alert, setAlert] = useState(null);
  const [exportUserList, setExportUserList] = useState("");

  const history = useHistory();

  useEffect(() => {
    let params = { limit: perPage, page_no: pageNo, role_id: 6 };
    setLoading(true);
    getAllUser(params);

    let dataExport = { search: searchInput };
    exportCustomer(dataExport)
  }, [pageNo]);

  const onEdit = (userId, linkDetails) => {
    history.push({
      pathname: `/admin/${linkDetails}/` + userId,
    });
  };

  //   const columns = [
  //     {
  //       name: "First Name",
  //       selector: (row) => row.firstName,
  //       sortable: true,
  //     },
  //     {
  //       name: "Last Name",
  //       selector: (row) => row.lastName,
  //       sortable: true,
  //     },
  //     {
  //       name: "Email",
  //       selector: (row) => row.email,
  //       sortable: true,
  //     },
  //     {
  //       name: "Username",
  //       selector: (row) => row.userName,
  //       sortable: true,
  //     },
  //     {
  //       name: "Created Date",
  //       selector: (row) => row.createdDate,
  //       sortable: true,
  //       cell: (row) => {
  //         if (row && row.createdDate) {
  //           return (
  //             <div className="d-flex justify-content-center">
  //               {new Date(row.createdDate).toLocaleDateString("en-GB", {
  //                 year: "numeric",
  //                 month: "short",
  //                 day: "numeric",
  //               })}
  //             </div>
  //           );
  //         }
  //       },
  //     },
  //     {
  //       name: "Register via",
  //       selector: (row) => row.registerVia,
  //       sortable: true,
  //     },
  //     {
  //       name: "Status",
  //       selector: (row) => row.status,
  //       cell: (row) => {
  //         return (
  //           <div>
  //             {row.status === 1 ? (
  //               <label className="custom-toggle custom-toggle-success mr-1">
  //                 <input
  //                   defaultChecked
  //                   type="checkbox"
  //                   onChange={() => activateUser(row.userId, row.status)}
  //                 />
  //                 <span className="custom-toggle-slider rounded-circle" />
  //               </label>
  //             ) : (
  //               <label className="custom-toggle custom-toggle-success mr-1">
  //                 <input
  //                   type="checkbox"
  //                   onChange={() => deactivateUser(row.userId, row.status)}
  //                 />
  //                 <span className="custom-toggle-slider rounded-circle" />
  //               </label>
  //             )}
  //           </div>
  //         );
  //       },
  //     },
  //     {
  //       name: "Action",
  //       cell: (row) => {
  //         return (
  //           <div className="d-flex justify-content-center">
  //             <>
  //               <Button
  //                 className="mr-2 btn-primary"
  //                 onClick={() => onEdit(row.userId)}
  //               >
  //                 <i className="fa fa-edit" />
  //               </Button>
  //             </>
  //           </div>
  //         );
  //       },
  //     },
  //   ];

  let count =1;
  const columns = [
    {
      name: "S. No.",
      selector: (row, index) => (pageNo - 1) * perPage + index + 1,
      // sortable: true,
    },
    {
      name: "Full Name",
      selector: (row) => row.fullName,
      sortable: true,
    },
    {
      name: "Email",
      minWidth: "250px",
      selector: (row) => row.email,
      sortable: true,
      compact: true,
    },
    {
      name: "Username",
      selector: (row) => row.userName,
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) => `${row.countryCode}-${row.phoneNumber}`,
      sortable: true,
    },
    {
      name: "Panic EventCount",
      selector: (row) => row.panicEventCount,
      sortable: true,
    },
    {
      name: "Red Alert",
      selector: (row) => row.redAlertSetting ? <span class="badge badge-success" style={{color: "green", fontWeight: "bold", fontSize: "11px", padding: "9px"}}>ON</span> : <span style={{color: "red", padding: "9px", fontWeight: "bold", fontSize: "11px"}} class="badge badge-danger">OFF</span>,
      sortable: true,
    },
    {
      maxWidth: "150px",
      center: true,
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => {
        return (
          <div>
            {row.status === 1 ? (
              <label className="custom-toggle custom-toggle-success">
                <input
                  defaultChecked
                  type="checkbox"
                  onChange={() => activateUser(row.userId, row.status)}
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
            ) : (
              <label className="custom-toggle custom-toggle-success">
                <input
                  type="checkbox"
                  onChange={() => deactivateUser(row.userId, row.status)}
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
            )}
          </div>
        );
      },
    },
    {
      center: true,
      name: "Action",
      maxWidth: "120px",
      cell: (row) => {
        return (
          <div className="d-flex justify-content-center">
            <>
              <Button
                className="mr-2 btn-primary"
                onClick={() => onEdit(row.userId, "customerdetails")}
              >
                <i className="fa fa-edit" />
              </Button>
              <Button
                className="mr-2 btn-primary"
                onClick={() => onEdit(row.userId, "subscription-details")}
              >
                <i className="fa fa-list" />
              </Button>
            </>
          </div>
        );
      },
    },
  ];

  const getAllUser = async (params) => {
    try {
      let response = await getUserList(params);
      console.log("search ",response)
      if (response && response.status === 200) {
        setUserList(response.data.data);
        setTotalRows(response.data.TotalResult);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setUserList([]);
    }
  };

  const onSearch = async (e) => {
    setSearchInput(e.target.value);
    let dataExport = { search: e.target.value };
    exportCustomer(dataExport)
    if (e.target.value.length > 2) {
      let params = {
        limit: perPage,
        page_no: 1,
        search: e.target.value,
        role_id: 6,
      };
      getAllUser(params);
    } else {
      let params = { limit: perPage, page_no: 1, role_id: 6 };
      getAllUser(params);
      exportCustomer()
    }
  };

  const handlePageChange = (page) => {
    setLoading(true);
    let params = {
      limit: perPage,
      page_no: page,
      search: searchInput,
      role_id: 6,
    };
    setPageNo(page)
    getAllUser(params);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    let params = {
      limit: newPerPage,
      page_no: page,
      search: searchInput,
      role_id: 6,
    };
    getAllUser(params);
    setPerPage(newPerPage);
  };

  const activateUser = (userId, status) => {
    // setShowAlert(true);
    setUserId(userId);
    setUserStatus(true);
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => onConfirmation(userId, status)}
        showCancel
        onCancel={() => onCancel()}
        confirmBtnBsStyle="secondary"
        confirmBtnText="Yes"
        cancelBtnBsStyle="danger"
        cancelBtnText="Cancel"
      >
        {status === 0
          ? "You want to activate this User!"
          : "You want to deactivate this User!"}
      </ReactBSAlert>
    );
  };

  const deactivateUser = (userId, status) => {
    // setShowAlert(true);
    setUserId(userId);
    setUserStatus(false);
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => onConfirmation(userId, status)}
        showCancel
        onCancel={() => onCancel()}
        confirmBtnBsStyle="secondary"
        confirmBtnText="Yes"
        cancelBtnBsStyle="danger"
        cancelBtnText="Cancel"
      >
        {status === 0
          ? "You want to activate this User!"
          : "You want to deactivate this User!"}
      </ReactBSAlert>
    );
  };

  const onCancel = () => {
    let params = {
      limit: perPage,
      page_no: 1,
      role_id: 6,
      search: searchInput,
    };
    setAlert(null);
    setLoading(true);
    getAllUser(params);
  };

  const onConfirmation = async (userId, status) => {
    let admin_status = status === 1 ? 0 : 1;
    let data = { status: admin_status };
    let success_msg =
      status === 0
        ? "User activated successfully."
        : "User deactivated successfully.";
    // const id = userId;

    try {
      let res = await updateUserStatus(userId, data);
      if (res && res.status === 200) {
        toast.success(success_msg, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        let params = {
          limit: perPage,
          page_no: 1,
          role_id: 6,
          search: searchInput,
        };
        setLoading(true);
        setTimeout(() => {
          getAllUser(params);
        }, 2000);
      }
    } catch (error) {
      if (error && error.response && error.response.status !== 500) {
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } else {
        toast.error("Oops something went wrong...", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    }

    setAlert(null);
  };

  const addCustomer = () => {
    let path = `/admin/customerdetails/add`;
    history.push(path);
  };

  const exportCustomer = async (dataExport) => {
    try {
      let response = await exportUser(dataExport);
      console.log("herer resp excel ",response)
      if (response && response.status === 200) {
        setExportUserList(response.data.data);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <>
      {alert}
      <AlternativeHeader
        parentComponent="User Management"
        pageTitle="User"
      />

      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">User</h3>
              </CardHeader>
              <CardBody>
                <Row className="mb-3">
                  <Col md="3">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fa fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Search"
                        name="searchInput"
                        type="text"
                        onChange={onSearch}
                        autoComplete="off"
                        value={searchInput || ""}
                      />
                    </InputGroup>
                  </Col>
                  <Col md="9">
                    <div className="d-flex justify-content-end align-items-center flex-wrap">
                      <Button
                        type="button"
                        className="btn btn-info mx-2"
                        onClick={addCustomer}
                      >
                        {" "}
                        Add User{" "}
                      </Button>
                      <CSVLink
                        data={exportUserList}
                        // onClick={exportCustomer}
                        filename={"CustomerList.csv"}
                        className="btn btn-info"
                      >
                        Export
                      </CSVLink>
                      {/* <Button type="button" className="btn btn-info mx-2" onClick={exportCustomer}> Export </Button> */}
                    </div>
                  </Col>
                </Row>
                <DataTable
                  columns={columns}
                  data={userList}
                  progressPending={loading}
                  progressComponent={
                    <Spinner
                      as="span"
                      size="lg"
                      role="status"
                      aria-hidden="true"
                    />
                  }
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
                  striped
                />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Customer;
