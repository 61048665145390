/**
 * @description : common file for all validation functions.
 * @author : Bhargav Lakhani
 * @since : 17th Dec 2021
 * @param: mail email id that needs to be validated.
 */

export const validateEmail = (mail) => {

    const errors = {};
    if (!/([^\s])/.test(mail)) errors["email"] = "Enter Valid Email.";
    if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(mail) && mail.length > 0) { errors["email"] = "Enter Valid Email."; }
    return Object.keys(errors).length === 0 ? null : errors;
}

export const validatePassword = (pswd) => {

    const errors = {};
    
    if (pswd.length < 8){
        errors["password"] = "Password Should Contain Minimum 8 Character";
    }
    else if (!/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/.test(pswd)) errors["password"] = "Please Enter Your Valid Password.";

    return Object.keys(errors).length === 0 ? null : errors;
}


export const ResetValidator = (data) => {
    const errors = {};
    if (!/([^\s])/.test(data['password'])) errors["password"] = "Please Enter Your Password.";

    if (!/([^\s])/.test(data['confirmPassword'])) errors["confirmPassword"] = "Please Enter Confirm Password";

    if (data['password'].length < 4 && data['password'].length > 0) {
        errors['password'] = 'Password Is Too short. It Should Be Minimum 8 Characters.'
    }

    if (data['password'] !== data['confirmPassword'] && data['confirmPassword'].length > 0) {
        errors['confirmPassword'] = "Passwords aren't matching"
    }

    return Object.keys(errors).length === 0 ? null : errors
}