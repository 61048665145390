import React, { useEffect, useState } from "react";
import AlternativeHeader from "components/Headers/AlternativeHeader";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Spinner,
  Modal,
} from "reactstrap";
import DataTable from "react-data-table-component";
import {
  getDeviceList,
  updateDeviceStatus,
  deleteDeviceInventory,
  addDeviceInInventory,
  updateDeviceInInventory,
  exportDeviceDataApi,
  getDeviceDetailsById,
} from "services/services";
import { useHistory } from "react-router";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import { importDeviceDataApi } from "services/services";

export default function DeviceManagement() {
  const [perPage, setPerPage] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [deviceList, setDeviceList] = useState([]);
  // const [selectStatus, setSelectStatus] = useState('');
  const [isAllocated, setIsAllocated] = useState(1);
  const [selectStatus, setSelectStatus] = useState(1);
  const [alert, setAlert] = useState(null);
  const [deviceManagementCsv, setDeviceManagementCsv] = useState("");

  const [importModal, setImportModal] = useState(false);
  const toggleImport = () => setImportModal(!importModal);

  const [isAdd, setIsAdd] = useState(true);
  const [deviceId, setDeviceId] = useState("");

  const [isFilterChange, setIsFilterChange] = useState(true);


  const [cSVErrorMessage, setCSVErrorMessage] = useState("");
  const [isUploadCsv, setIsUploadCsv] = useState(false);

  const [deviceDetails, setDeviceDetails] = useState({
    name: "",
    id: "",
    macAddress: null,
  });

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  useEffect(() => {
    let params = {
      limit: perPage,
      page_no: pageNo,
      active_status: selectStatus,
      keyword: searchInput,
      allocation_status: isAllocated,
    };

    setLoading(true);
    getAllDeviceList(params);
  }, []);

  let count = 1;

  const columns = [
    {
      name: "S. No.",
      maxWidth: "70px",
      sortable: true,
      cell: (row, index) => (pageNo - 1) * perPage + index + 1,
    },
    {
      name: "Device Name",
      maxWidth: "300px",
      selector: (row) => row?.deviceName,
      sortable: true,
      compact: true,
    },
    {
      name: "Device ID",
      maxWidth: "200px",
      selector: (row) => row?.deviceId,
      sortable: true,
    },
    {
      name: "User Name",
      maxWidth: "200px",
      selector: (row) =>
        row?.userDeviceInventory?.length <= 0
          ? "N/A"
          : row?.userDeviceInventory[0]?.deviceInventoryUser?.userName,
      sortable: true,
    },
    {
      maxWidth: "200px",
      center: true,
      name: "Status",
      selector: (row) => row?.isActive,
      cell: (row) => {
        return (
          <div>
            <label className="custom-toggle custom-toggle-success">
              <input
                checked={row?.isActive}
                type="checkbox"
                onChange={() => activateDeactiveDevice(row.id, row?.isActive)}
              />
              <span className="custom-toggle-slider rounded-circle" />
            </label>
          </div>
        );
      },
    },
    {
      center: true,
      name: "Action",
      maxWidth: "120px",
      cell: (row) => {
        return (
          <div className="d-flex justify-content-center">
            <>
              <Button
                className="mr-2 btn-primary"
                onClick={() => onEdit(row.id)}
              >
                <i className="fa fa-edit" />
              </Button>
              <Button
                className="mr-2 btn-primary"
                onClick={() => onDelete(row.id)}
              >
                <i className="fa fa-trash" />
              </Button>
            </>
          </div>
        );
      },
    },
  ];

  const addCustomerData = () => {
    setIsAdd(true);
    setDeviceDetails({
      name: "",
      id: "",
      macAddress: null,
    });
    toggle();
  };

  const addEditDevice = async () => {
    let params = {
      deviceName: deviceDetails.name,
      deviceId: deviceDetails.id,
      macAddress: null,
    };

    try {
      let response = null;
      if (isAdd) {
        response = await addDeviceInInventory(params);
      } else {
        response = await updateDeviceInInventory(deviceId, params);
      }
      if (response && response.status === 200) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        toggle();
        let params = {
          limit: perPage,
          page_no: pageNo,
          active_status: selectStatus,
          keyword: searchInput,
          allocation_status: isAllocated,
        };
        setLoading(true);
        setTimeout(() => {
          getAllDeviceList(params);
        }, 2000);
      }
    } catch (err) {
      setLoading(false);
      toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const onDeleteConfirmation = async (deviceId) => {
    let success_msg = "Device deleted successfully.";

    try {
      let res = await deleteDeviceInventory(deviceId);
      if (res && res.status === 200) {
        toast.success(success_msg, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        let params = {
          limit: perPage,
          page_no: pageNo,
          active_status: selectStatus,
          keyword: searchInput,
          allocation_status: isAllocated,
        };
        setLoading(true);
        setTimeout(() => {
          getAllDeviceList(params);
        }, 2000);
      }
    } catch (error) {
      if (error && error.response && error.response.status !== 500) {
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } else {
        toast.error("Oops something went wrong...", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    }

    setAlert(null);
  };

  const onDelete = (deviceId) => {
    setAlert(
      <ReactBSAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure you want to Remove the Device?"
        onConfirm={() => onDeleteConfirmation(deviceId)}
        showCancel
        onCancel={() => onCancel()}
        confirmBtnBsStyle="danger"
        confirmBtnText="Remove"
        cancelBtnBsStyle="secondary"
        cancelBtnText="Cancel"
      ></ReactBSAlert>
    );
  };

  const onEdit = async (id) => {
    setIsAdd(false);
    setDeviceId(id);
    try {
      let response = await getDeviceDetailsById(id);
      if (response && response.status === 200) {
        let dataRes = response.data.data;
        console.log("dataRes", dataRes);
        setDeviceDetails({
          name: dataRes.deviceName,
          id: dataRes.deviceId,
          macAddress: dataRes.macAddress,
        });
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setDeviceDetails({
        name: "",
        id: "",
        macAddress: null,
      });
    }
    toggle();
  };

  const onConfirmation = async (deviceId, status) => {
    let success_msg =
      status === 0
        ? "Device activated successfully."
        : "Device deactivated successfully.";
    try {
      let res = await updateDeviceStatus(deviceId);
      if (res && res.status === 200) {
        toast.success(success_msg, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        let params = {
          limit: perPage,
          page_no: pageNo,
          active_status: selectStatus,
          keyword: searchInput,
          allocation_status: isAllocated,
        };
        setLoading(true);
        setTimeout(() => {
          getAllDeviceList(params);
        }, 2000);
      }
    } catch (error) {
      if (error && error.response && error.response.status !== 500) {
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } else {
        toast.error("Oops something went wrong...", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    }

    setAlert(null);
  };

  const onCancel = () => {
    setAlert(null);
  };

  const activateDeactiveDevice = (deviceId, status) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => onConfirmation(deviceId, status)}
        showCancel
        onCancel={() => onCancel()}
        confirmBtnBsStyle="danger"
        confirmBtnText="Yes"
        cancelBtnBsStyle="secondary"
        cancelBtnText="Cancel"
      >
        {status === 0
          ? "You want to activate this Device!"
          : "You want to deactivate this Device!"}
      </ReactBSAlert>
    );
  };

  const getAllDeviceList = async (params) => {
    try {
      let response = await getDeviceList(params);
      if (response && response.status === 200) {
        setDeviceList(response.data.data);
        setTotalRows(response.data.totalResult);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setDeviceList([]);
    }
  };

  const onSearch = async (e) => {
    setSearchInput(e.target.value);
    setPageNo(1)
  };

  const handlePageChange = (page) => {
    setLoading(true);
    let params = {
      limit: perPage,
      page_no: page,
      active_status: selectStatus,
      keyword: searchInput,
      allocation_status: isAllocated,
    };
    setPageNo(page)
    getAllDeviceList(params);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    let params = {
      limit: newPerPage,
      page_no: page,
      active_status: selectStatus,
      keyword: searchInput,
      allocation_status: isAllocated,
    };
    getAllDeviceList(params);
    setPerPage(newPerPage);
  };

  const handleSelect = (e) => {
    setIsAllocated(e.target.value);
  };

  const handleSelectStatus = (e) => {
    setSelectStatus(e.target.value);
  };

  const filterApply = () => {
    setLoading(true);
    setPageNo(1);
    let params = {
      limit: perPage,
      page_no: 1,
      active_status: selectStatus,
      keyword: searchInput,
      allocation_status: isAllocated,
    };
    getAllDeviceList(params);
    setIsFilterChange(true);
  };

  const clearFilter = () => {
    setLoading(true);
    setPageNo(1);
    setSearchInput("");
    setIsAllocated(1);
    setSelectStatus(1);
    let params = {
      limit: perPage,
      page_no: 1,
      active_status: 1,
      keyword: "",
      allocation_status: 1,
    };
    getAllDeviceList(params);
    setIsFilterChange(true);
  };

  const exportDeviceCsvData = async () => {
    let dataCsv = {
      active_status: selectStatus,
      keyword: searchInput,
      allocation_status: isAllocated,
    };
    try {
      let response = await exportDeviceDataApi(dataCsv);
      if (response && response.status === 200) {
        setDeviceManagementCsv(response.data);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isFilterChange) {
      setIsFilterChange(false);
      exportDeviceCsvData();
    }
  }, [isFilterChange]);

  const deviceDemoCsv = [
    {
      deviceId: "",
      deviceName: "",
      macAddress: "",
    },
  ];

  const handleOnChangeCsvFile = (e) => {
    const fileCheck = e.target.files[0];
    if (fileCheck !== undefined && fileCheck !== null) {
      if (fileCheck.type !== "text/csv") {
        return setCSVErrorMessage("Only CSV files are allowed");
      }
      setCSVErrorMessage("");
      handleOnSubmitCsvFile(fileCheck);
    } else {
      setCSVErrorMessage("Please select CSV file.");
    }
  };

  const handleOnSubmitCsvFile = async (fileCheck) => {
    setIsUploadCsv(true);
    const formData = new FormData();
    formData.append("file", fileCheck);
    try {
      let response = await importDeviceDataApi(formData);
      if (response && response.status === 200) {
        setImportModal(false)
        toast.success("File uploaded successfully.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setIsUploadCsv(false);
        setLoading(true);
        let params = {
          limit: perPage,
          page_no: 1,
          active_status: selectStatus,
          keyword: searchInput,
          allocation_status: isAllocated,
        };
        getAllDeviceList(params);
      }
    } catch (error) {
      setIsUploadCsv(false);
      setImportModal(false)
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  return (
    <>
      {alert}
      <AlternativeHeader pageTitle="Device Management" parentComponent="" />
      <Modal
        className="modal-dialog-centered"
        isOpen={modal}
        toggle={toggle}
        modalTransition={{ timeout: 100 }}
        scrollable={true}
      >
        <div className="modal-header p-2">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggle}
          >
            <span aria-hidden={true} style={{ fontSize: "25px" }}>
              ×
            </span>
          </button>
        </div>
        <div className="modal-body p-2">
          <div className="justify-content-center text-center p-2" id="content">
            <h1>{isAdd ? "Add" : "Update"} Device</h1>
            <Row style={{ marginTop: "15px" }}>
              <Col className="mb-12" md="12">
                <label className="form-control-label" htmlFor="deviceName">
                  Device Name
                </label>
                <Input
                  className="custom_file_input"
                  id="deviceName"
                  type="text"
                  value={deviceDetails.name}
                  onChange={(e) =>
                    setDeviceDetails({
                      name: e.target.value,
                      id: deviceDetails.id,
                    })
                  }
                />
              </Col>
              <Col className="mb-12" md="12">
                <label className="form-control-label" htmlFor="deviceId">
                  Device ID
                </label>
                <Input
                  className="custom_file_input"
                  id="deviceId"
                  type="text"
                  value={deviceDetails.id}
                  onChange={(e) =>
                    setDeviceDetails({
                      name: deviceDetails.name,
                      id: e.target.value,
                    })
                  }
                />
              </Col>
              <Col className="mb-12" md="12" style={{ marginTop: "20px" }}>
                <Button className="m-2 btn btn-info" onClick={() => toggle()}>
                  Cancel
                </Button>
                <Button
                  className="m-2 btn btn-info"
                  onClick={() => addEditDevice()}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>

      <Modal
        className="modal-dialog-centered"
        isOpen={importModal}
        toggle={toggleImport}
        modalTransition={{ timeout: 100 }}
        scrollable={true}
      >
        <div className="modal-header p-2">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleImport}
          >
            <span aria-hidden={true} style={{ fontSize: "25px" }}>
              ×
            </span>
          </button>
        </div>
        <div className="modal-body p-2">
          <div className="justify-content-center text-center p-2" id="content">
            <h3>Please download the below file for example.</h3>
            <Row style={{ marginTop: "20px" }}>
              <Col className="mb-12" md="12">
                <CSVLink
                  data={deviceDemoCsv}
                  filename={"DeviceManagement.csv"}
                  className="btn btn-success"
                >
                  Download File
                </CSVLink>
              </Col>
              <Col
                className="mb-12 d-flex justify-content-center"
                md="12"
                style={{ marginTop: "15px" }}
              >
                <label
                  className="upload_section"
                  style={{
                    padding: "11px",
                    background: "#26a8ff",
                    display: "table",
                    color: "#ffff",
                    borderRadius: "10px",
                    width: "10vw",
                    cursor: "pointer",
                  }}
                >
                  <span>Import File </span>{" "}
                  {isUploadCsv && (
                    <Spinner
                      as="span"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  <input
                    type={"file"}
                    id={"csvFileInput"}
                    accept={".csv"}
                    onChange={handleOnChangeCsvFile}
                    style={{ display: "none" }}
                  />
                </label>
                {cSVErrorMessage !== "" && (
                  <p style={{ color: "red" }}>{cSVErrorMessage}</p>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Device Management</h3>
              </CardHeader>
              <CardBody>
                <Row className="mb-3">
                  <Col md="4">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fa fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Search"
                        name="searchInput"
                        type="text"
                        onChange={onSearch}
                        autoComplete="off"
                        value={searchInput || ""}
                      />
                    </InputGroup>
                  </Col>
                  <Col md="8">
                    <div className="d-flex justify-content-end align-items-center flex-wrap">
                      <Row className="mb-1">
                        <Col md="3">
                          <Input
                            type="select"
                            name="isAllocated"
                            onChange={handleSelect}
                            id="device"
                            value={isAllocated}
                          >
                            <option value={1}>All</option>
                            <option value={2}>Allocated</option>
                            <option value={3}>Not Allocated</option>
                          </Input>
                        </Col>
                        <Col md="3">
                          <Input
                            type="select"
                            name="selectStatus"
                            onChange={handleSelectStatus}
                            id="status"
                            value={selectStatus}
                          >
                            <option value={1}>All</option>
                            <option value={3}>Active</option>
                            <option value={4}>Inactive</option>
                          </Input>
                        </Col>
                        <Col md="2">
                          <Button
                            type="button"
                            className="btn btn-secondary mx-2"
                            onClick={clearFilter}
                            disabled={loading}
                          >
                            {" "}
                            Clear
                          </Button>
                        </Col>
                        <Col md="2">
                          <Button
                            type="button"
                            className="btn btn-primary mx-2"
                            onClick={filterApply}
                            disabled={loading}
                          >
                            {" "}
                            Apply
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col md="12" style={{ margin: "25px" }}>
                    <div className="d-flex justify-content-end align-items-center flex-wrap">
                      <Col md="5">
                        <Button
                          type="button"
                          className="btn btn-info mx-2"
                          onClick={() => setImportModal(true)}
                        >
                          {" "}
                          Import Device
                        </Button>
                        <CSVLink
                          data={deviceManagementCsv}
                          filename={"DeviceManagement.csv"}
                          className="btn btn-info"
                          // onClick={() => exportDeviceCsvData()}
                        >
                          Export
                        </CSVLink>
                        <Button
                          type="button"
                          className="btn btn-info mx-2"
                          onClick={addCustomerData}
                        >
                          {" "}
                          Add +
                        </Button>
                      </Col>
                    </div>
                  </Col>
                </Row>
                <DataTable
                  columns={columns}
                  data={deviceList}
                  progressPending={loading}
                  progressComponent={
                    <Spinner
                      as="span"
                      size="lg"
                      role="status"
                      aria-hidden="true"
                    />
                  }
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
                  striped
                />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
