import AlternativeHeader from 'components/Headers/AlternativeHeader';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardHeader, Col, Container, Form, Input, Row, Spinner } from 'reactstrap';
import { getAdminById, createAdmin, updateAdminById } from 'services/services';
import { validatePassword, validateEmail } from 'shared/validation';

const AdminDetails = (props) => {
    const [disabled, setDisabled] = useState(false);
    const [errors, setErrors] = useState(false);
    const [addEdit, setAddEdit] = useState();
    const [adminId, setAdminId] = useState();

    // const [focused, setFocused] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [firstNameState, setFirstNameState] = useState(null);
    const [lastName, setLastName] = useState("");
    const [lastNameState, setLastNameState] = useState(null);
    // const [disableField, setDisableField] = useState("");

    const [emailId, setEmailId] = useState("");
    const [emailIdState, setEmailIdState] = useState(null);
    const [password, setPassword] = useState("");
    const [passwordState, setPasswordState] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordState, setConfirmPasswordState] = useState(null);
    const [profilePic, setProfilePic] = useState("");
    const [profilePicURL, setProfilePicURL] = useState("");
    const [profilePicName, setProfilePicName] = useState("");

    const history = useHistory();
    useEffect(() => {
        const userId = props.location.pathname.substring(props.location.pathname.lastIndexOf('/') + 1);
        if (userId !== "add") {
            setAdminId(userId);
            setAddEdit("edit");
            getAdminInfo(userId);
        } else {
            setAddEdit("add");
        }
    }, []);

    const getAdminInfo = async (userId) => {
        const id = userId;
        try {
            let res = await getAdminById(id);
            if (res && res.status === 200) {
                setFirstName(res.data.data.firstName);
                setLastName(res.data.data.lastName);
                setEmailId(res.data.data.email);
                setProfilePicURL(res.data.data.profilePic);
                setProfilePicName(res.data.data.profilePic);
                // setProfilePic(url);
                // setDisableField(disabled);
            }
        } catch (err) {
            toast.error(err.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000
            });
            history.replace({ pathname: '/admin/adminlist' });
        }
    }
    const handleChange = (e) => {
        let error;
        if (e.target.name === "email") {
            error = validateEmail(e.target.value);
            if (error) setEmailIdState("invalid");
        } else if (e.target.name === "password") {
            error = validatePassword(e.target.value);
            if (error || e.target.value === "") setPasswordState("invalid");
        } else if (e.target.name === "confirm_password") {
            if (e.target.value !== password) {
                // error = "Password Not matched";
                if (error) setConfirmPasswordState("invalid");
            }
        } else if (e.target.name === "firstname") {
            if (e.target.value === "") {
                error = "Enter First Name";
                setFirstNameState("invalid");
            }
        } else if (e.target.name === "lastname") {
            if (e.target.value === "") {
                error = "Enter Last Name";
                setLastNameState("invalid");
            }
        }
    }

    const onCancel = () => {
        let path = `/admin/adminlist`;
        history.push(path);
    }

    const handelSubmit = async (e) => {
        e.preventDefault();
        if (!errors) {
            setDisabled(true);
            // debugger;
            const id = adminId;
            let data = new FormData();
            data.append('first_name', firstName)
            data.append('last_name', lastName);
            data.append('email', emailId);
            data.append('profile_pic', profilePic);
            try {
                let res = await updateAdminById(id, data);
                if (res && res.status === 200) {
                    toast.success("Admin updated successfully.", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000
                    });
                    setTimeout(() => {
                        setDisabled(false);
                        props.history.replace({ pathname: '/admin/adminlist', search: props.location.search });
                    }, 500);
                }
            } catch (err) {
                setDisabled(true);
                if (err.response.status !== 500) {
                    toast.error(err.response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000
                    });
                } else {
                    toast.error('Oops something went wrong...', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000
                    });
                }
            }
        }
    }

    const handelCreate = async (e) => {
        e.preventDefault();
        if (!errors) {
            setDisabled(true);
            let data = new FormData();
            data.append('first_name', firstName)
            data.append('last_name', lastName);
            data.append('email', emailId);
            data.append('password', password);
            data.append('profile_pic', profilePic);

            try {
                let res = await createAdmin(data);
                if (res && res.status === 200) {
                    toast.success("Admin created successfully.", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000
                    });
                    setTimeout(() => {
                        setDisabled(false);
                        props.history.replace({ pathname: '/admin/adminlist', search: props.location.search });
                    }, 500);
                }
            } catch (err) {
                setDisabled(false);
                if (err.response.status !== 500) {
                    toast.error(err.response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000
                    });
                } else {
                    toast.error('Oops something went wrong...', {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000
                    });
                }
            }
        }
    }

    const validateCustomStylesForm = () => {
        if (firstName === "") { setFirstNameState("invalid"); }
        if (lastName === "") { setLastNameState("invalid"); }
        if (emailId === "") { setEmailIdState("invalid"); }
        if (addEdit === "add") {
            if (password === "") { setPasswordState("invalid"); setErrors(true); }
            if (confirmPassword === "") { setConfirmPasswordState("invalid"); setErrors(true) }
        } else {
            setPasswordState("valid")
            setConfirmPasswordState("valid");
        }
        if (firstName === "" || lastName === "" || emailId === "") {
            setErrors(true);
        }
    };

    const onChangeProfile = (e) => {
        setProfilePic(e.target.files[0]);
        setProfilePicName(e.target.files[0].name);
        setProfilePicURL(URL.createObjectURL(e.target.files[0]))
    };

    return (
        <>
            <AlternativeHeader parentComponent="Admin Management" pageTitle={addEdit === "edit" ? "Edit Admin" : "Add Admin"} />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader><h3 className="mb-0">{addEdit === "edit" ? "Edit Admin" : "Add Admin"}</h3></CardHeader>
                            <CardBody>
                                <Form className="needs-validation" noValidate onSubmit={addEdit === "edit" ? handelSubmit : handelCreate}>
                                    <div className="form-row">
                                        <Col className="mb-3" md="4">
                                            <label className="form-control-label" htmlFor="validationCustom01">First name</label>
                                            <Input id="validationCustom01" placeholder="First name" type="text" valid={firstNameState === "valid"} invalid={firstNameState === "invalid"} name="firstname"
                                                onChange={(e) => {
                                                    setFirstName(e.target.value);
                                                    if (e.target.value === "") {
                                                        setFirstNameState("invalid");
                                                    } else {
                                                        setFirstNameState("valid");
                                                    }
                                                }} value={firstName}
                                            />
                                            <div className="invalid-feedback">Please Enter First Name.</div>
                                        </Col>
                                        <Col className="mb-3" md="4">
                                            <label className="form-control-label" htmlFor="validationCustom02">Last name</label>
                                            <Input id="validationCustom02" placeholder="Last name" type="text" valid={lastNameState === "valid"} invalid={lastNameState === "invalid"} name="lastname"
                                                onChange={(e) => {
                                                    setLastName(e.target.value);
                                                    if (e.target.value === "") {
                                                        setLastNameState("invalid");
                                                    } else {
                                                        setLastNameState("valid");
                                                    }
                                                }} value={lastName}
                                            />
                                            <div className="invalid-feedback">Please Enter Last Name.</div>
                                        </Col>
                                        <Col className="mb-3" md="4">
                                            <label className="form-control-label" htmlFor="validationCustom03">Email Id</label>
                                            <Input id="validationCustom03" placeholder="Email Id" name="email" type="email" valid={emailIdState === "valid"} invalid={emailIdState === "invalid"}
                                                onChange={(e) => {
                                                    setEmailId(e.target.value);
                                                    if (e.target.value === "") {
                                                        setEmailIdState("invalid");
                                                    } else {
                                                        setEmailIdState("valid");
                                                    }
                                                    handleChange(e);
                                                }} value={emailId}
                                            />
                                            <div className="invalid-feedback">Please Enter valid Email Id.</div>
                                        </Col>
                                    </div>
                                    <div className="form-row">
                                        <Col className="mb-3" md="4">
                                            <label className="form-control-label" htmlFor="validationCustom04">Password</label>
                                            {addEdit === "edit" ?
                                                <Input placeholder="Password" type="password" value="********" disabled />
                                                : <Input id="validationCustom04" placeholder="Password" name="password" type="password" valid={passwordState === "valid"} invalid={passwordState === "invalid"}
                                                    onChange={(e) => {
                                                        setPassword(e.target.value);
                                                        if (e.target.value === "") {
                                                            setPasswordState("invalid");
                                                        } else {
                                                            setPasswordState("valid");
                                                        }
                                                        handleChange(e);
                                                    }} value={password}
                                                />
                                            }
                                            <div className="invalid-feedback">Please Enter valid Password.</div>
                                        </Col>
                                        <Col className="mb-3" md="4">
                                            <label className="form-control-label" htmlFor="validationCustom05">Confirm Password</label>
                                            {addEdit === "edit" ?
                                                <Input placeholder="Password" type="password" value="********" disabled />
                                                : <Input id="validationCustom05" placeholder="Confirm Password" name="confirm_password" type="password" valid={confirmPasswordState === "valid"} invalid={confirmPasswordState === "invalid"}
                                                    onChange={(e) => {
                                                        setConfirmPassword(e.target.value);
                                                        if (e.target.value === "" || e.target.value !== password) {
                                                            setConfirmPasswordState("invalid");
                                                        } else {
                                                            setConfirmPasswordState("valid");
                                                        }
                                                        handleChange(e);
                                                    }} value={confirmPassword}
                                                />
                                            }
                                            <div className="invalid-feedback">Password not match</div>
                                        </Col>
                                        <Col className="mb-3" md="4">
                                            <div className='custom_file_upload'>
                                                <label className="form-control-label" htmlFor="validationCustom07">Profile</label>
                                                <Input className='custom_file_input' id="validationCustom07" type="file" onChange={(e) => { onChangeProfile(e) }} />
                                                <Input id="validationCustom07" placeholder="Profile" type="text" value={profilePicName} />
                                                <div className="invalid-feedback">Please Enter valid Profile image.</div>
                                            </div>
                                        </Col>
                                    </div>
                                    <div className="form-row">
                                        {profilePicURL !== "" ?
                                            <>
                                                <Col className="mb-3" md="4">
                                                    <label className="form-control-label" htmlFor="validationCustom07">Profile Preview</label>
                                                    <div className="img_cls"><img className="rounded-circle imageFitCover" height="140px" width="140px" src={profilePicURL} alt="Profile" /></div>
                                                </Col>
                                                <Col md="8" className='text-right my-3 align-self-end'>
                                                    <Button color="secondary" type="button" onClick={onCancel}>Cancel</Button>
                                                    <Button color="primary" type="submit" onClick={validateCustomStylesForm}>{disabled && <Spinner as="span" size="sm" role="status" aria-hidden="true" />}{' '}{addEdit === "edit" ? "Update" : "Submit"}</Button>
                                                </Col>
                                            </>
                                            : <Col md="12" className='text-right mt-3'>
                                                <Button color="secondary" type="button" onClick={onCancel}>Cancel</Button>
                                                <Button color="primary" type="submit" onClick={validateCustomStylesForm}>{disabled && <Spinner as="span" size="sm" role="status" aria-hidden="true" />}{' '}{addEdit === "edit" ? "Update" : "Submit"}</Button>
                                            </Col>
                                        }
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
}
export default AdminDetails;