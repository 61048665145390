import React, { createRef, useState } from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Label,
  Input,
  Spinner,
  Modal,
} from "reactstrap";
import DataTable from "react-data-table-component";

// core components
import AlternativeHeader from "components/Headers/AlternativeHeader.js";
import { getUserStatistics, getPostStatistics } from "services/services";

function Alternative() {
  const fromDatePicker = createRef();
  const toDatePicker = createRef();

  const currentDate = new Date(Date.now()).toISOString().slice(0, 10);
  const d = new Date();
  d.setDate(d.getDate() - 1);
  const pastDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
    .toISOString()
    .slice(0, 10);
  const [loading, setLoading] = useState(false);
  const [userStatistics, setUserStatistics] = useState();
  const [postStatistics, setPostStatistics] = useState();
  const [filterData, setFilterData] = useState({
    fromDate: pastDate,
    toDate: currentDate,
    privacyType: "",
  });
  const [maxFromDate, setMaxFromDate] = useState(d.toISOString().slice(0, 10));
  const [maxToDate, setMaxToDate] = useState(currentDate);
  const [columns, setColumns] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState();
  const toggle = () => setModal(!modal);
  const [modelType, setModelType] = useState("");
  const { fromDate, toDate, privacyType } = filterData;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilterData((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  React.useEffect(() => {
    setLoading(true);
    getAllUserStatistics();
  }, []);

  // React.useEffect(async () => {
  //   setLoading(true);
  //   // const d = new Date(toDate);
  //   // d.setDate(d.getDate() - 1);
  //   // setMaxFromDate(d.toISOString().slice(0, 10));
  //   // setMaxFromDate(toDate);
  //   // if (toDate > fromDate) {
  //   // } else {
  //   //   filterData.fromDate = toDate;
  //   // }
  //   // fromDatePicker.current.value = toDate;
  //   // const da = new Date(fromDate);
  //   // da.setDate(da.getDate() + 1);
  //   // setMaxToDate(da.toISOString().slice(0, 10));
  //   // await getAllPostStatistics(filterData);
  // }, []);

  const getAllUserStatistics = async () => {
    try {
      let response = await getUserStatistics();
      console.log(response.data.data);
      if (response && response.status === 200) {
        setUserStatistics(response.data.data);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setUserStatistics([]);
    }
  };

  // const getAllPostStatistics = async (filterData) => {
  //   try {
  //     let response = await getPostStatistics(filterData);
  //     console.log(response.data);
  //     if (response && response.status === 200) {
  //       setPostStatistics(response.data);
  //       setLoading(false);
  //     }
  //   } catch (err) {
  //     setLoading(false);
  //     setPostStatistics([]);
  //   }
  // };

  // const displayColumns = async (col) => {
  //   switch (col) {
  //     case 1:
  //       setColumns([
  //         {
  //           name: "Name",
  //           selector: (row) => row?.userName,
  //           sortable: true,
  //           center: true,
  //         },
  //         {
  //           name: "Date Joined",
  //           sortable: true,
  //           center: true,
  //           selector: (row) => row.createdDate,
  //           cell: (row) => {
  //             if (row && row?.createdDate) {
  //               return (
  //                 <div className="d-flex justify-content-center">
  //                   {new Date(row?.createdDate).toLocaleDateString("en-GB", {
  //                     year: "numeric",
  //                     month: "short",
  //                     day: "numeric",
  //                   })}
  //                 </div>
  //               );
  //             }
  //           },
  //         },
  //       ]);
  //       break;
  //     case 2:
  //       setColumns([
  //         {
  //           name: "Name",
  //           selector: (row) => row?.userName,
  //           sortable: true,
  //           center: true,
  //         },
  //       ]);
  //       break;
  //     case 3:
  //       setColumns([
  //         {
  //           name: "Name",
  //           selector: (row) => row?.userName,
  //           sortable: true,
  //           center: true,
  //         },
  //       ]);
  //       break;
  //     case 4:
  //       setColumns([
  //         {
  //           name: "Name",
  //           selector: (row) => row?.userName,
  //           sortable: true,
  //           center: true,
  //         },
  //         {
  //           name: "Date Joined",
  //           sortable: true,
  //           center: true,
  //           selector: (row) => row.joinedDate,
  //           cell: (row) => {
  //             if (row && row?.joinedDate) {
  //               return (
  //                 <div className="d-flex justify-content-center">
  //                   {new Date(row?.joinedDate).toLocaleDateString("en-GB", {
  //                     year: "numeric",
  //                     month: "short",
  //                     day: "numeric",
  //                   })}
  //                 </div>
  //               );
  //             }
  //           },
  //         },
  //         {
  //           name: "Last Active Date",
  //           sortable: true,
  //           center: true,
  //           selector: (row) => row.lastActiveDate,
  //           cell: (row) => {
  //             if (row && row?.lastActiveDate) {
  //               return (
  //                 <div className="d-flex justify-content-center">
  //                   {new Date(row?.lastActiveDate).toLocaleDateString("en-GB", {
  //                     year: "numeric",
  //                     month: "short",
  //                     day: "numeric",
  //                   })}
  //                 </div>
  //               );
  //             }
  //           },
  //         },
  //       ]);
  //   }
  // };

  // const displayModel = async (data, col) => {
  //   if (col !== 0) {
  //     displayColumns(col);
  //   }
  //   setModelType(col);
  //   setModalData(data);
  //   toggle();
  // };

  return (
    <>
      <AlternativeHeader pageTitle="Dashboard" parentComponent="" />

      <Modal
        className="modal-dialog-centered"
        isOpen={modal}
        toggle={toggle}
        modalTransition={{ timeout: 100 }}
      >
        {modelType === 0 ? (
          <>
            <div className="modal-header p-2">
              {/* <h6 className="modal-title" id="modal-title-default">
                {modalData?.experience_description}
              </h6> */}
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={toggle}
              >
                <span aria-hidden={true} style={{ fontSize: "25px" }}>
                  ×
                </span>
              </button>
            </div>
            <div className="modal-body p-1">
              <div className="d-flex justify-content-center text-center">
                {modalData?.experience_type === 1 ? (
                  <a href={modalData?.media_file} target="_blank">
                    <img
                      src={modalData?.media_file}
                      height="400px"
                      width="400px"
                    />
                  </a>
                ) : (
                  <video width="400" height="400" controls>
                    <source src={modalData?.media_file} type="video/mp4" />
                    {/* <source src={modalData.media_file} type="video/ogg" /> */}
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
            </div>
            <div className="modal-footer p-4">
              <h6 className="modal-title m-auto" id="modal-title-default">
                {modalData?.experience_description}
              </h6>
            </div>
          </>
        ) : (
          <>
            <div className="modal-header p-2">
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={toggle}
              >
                <span aria-hidden={true} style={{ fontSize: "25px" }}>
                  ×
                </span>
              </button>
            </div>
            <div className="modal-body pt-0">
              <DataTable
                columns={columns}
                data={modalData}
                progressPending={loading}
                progressComponent={
                  <Spinner
                    as="span"
                    size="lg"
                    role="status"
                    aria-hidden="true"
                  />
                }
                fixedHeader
                fixedHeaderScrollHeight="500px"
                striped
              />
            </div>
          </>
        )}
      </Modal>

      <Container className="mt--6" fluid>
        <Row>
          <Col md="12" xl="12">
            <Card>
              <CardHeader className="h3"> App Statistics </CardHeader>
              <CardBody>
                <Row className="postStaticsCard">
                  <Col md="6" xl="4">
                    <Card className="bg-gradient-primary border-0">
                      <CardBody className="text-left">
                        <Row>
                          <div className="col">
                            <CardTitle className="mb-2 h3 text-white font-weight-bold">
                              Total Users
                            </CardTitle>
                            <span className="h2 text-white font-weight-bold">
                              {userStatistics?.totalUsers}
                            </span>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6" xl="4">
                    <Card className="bg-gradient-primary border-0">
                      <CardBody className="text-left">
                        <Row>
                          <div className="col">
                            <CardTitle className="mb-2 h3 text-white font-weight-bold">
                              Total Panic Events
                            </CardTitle>
                            <span className="h2 text-white font-weight-bold">
                            {userStatistics?.totalPanicEvents}
                            </span>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6" xl="4">
                    <Card className="bg-gradient-primary border-0">
                      <CardBody className="text-left">
                        <Row>
                          <div className="col">
                            <CardTitle className="mb-2 h3 text-white font-weight-bold">
                              Total Alarm Notifications
                            </CardTitle>
                            <span className="h2 text-white font-weight-bold">
                            {userStatistics?.totalSentNotifications}
                            </span>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6" xl="4">
                    <Card className="bg-gradient-primary border-0">
                      <CardBody className="text-left">
                        <Row>
                          <div className="col">
                            <CardTitle className="mb-2 h3 text-white font-weight-bold">
                              Total Recordings
                            </CardTitle>
                            <span className="h2 text-white font-weight-bold">
                              {/* {postStatistics?.total_posts} */}
                              {userStatistics?.totalRecordings}
                            </span>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6" xl="4">
                    <Card className="bg-gradient-primary border-0">
                      <CardBody className="text-left">
                        <Row>
                          <div className="col">
                            <CardTitle className="mb-2 h3 text-white font-weight-bold">
                              Total Active Emergency Contacts
                            </CardTitle>
                            <span className="h2 text-white font-weight-bold">
                              {/* {postStatistics?.total_posts} */}
                              {userStatistics?.activeEmergencyContacts}
                            </span>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col md="6" xl="4">
                    <Card className="bg-gradient-primary border-0">
                      <CardBody className="text-left">
                        <Row>
                          <div className="col">
                            <CardTitle className="mb-2 h3 text-white font-weight-bold">
                              Total Inactive Emergency Contacts
                            </CardTitle>
                            <span className="h2 text-white font-weight-bold">
                              {/* {postStatistics?.total_posts} */}
                              {userStatistics?.inactiveEmergencyContacts}
                            </span>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        
      </Container>
    </>
  );
}

export default Alternative;
