import React, { useEffect, useState, useRef } from "react";
import AlternativeHeader from "components/Headers/AlternativeHeader";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Spinner,
  Modal,
} from "reactstrap";
import JoditEditor from "jodit-react";
import DataTable, { createTheme } from "react-data-table-component";
import {
  getFlaggedPosts,
  flaggedPostAction,
  getEmailTemplates,
  setEmailTemplateStatus,
  getTemplates,
  updateEmailTemplate,
} from "services/services";
import { useHistory } from "react-router";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { toast } from "react-toastify";
import Welcome from "./EmailTemplates/welcome.jsx";
import "./modal.css";

// const wel = require("./EmailTemplates/welcome.html");

// const wel =
//   '<h1>Hello, world!</h1><label disabled>hhhhhh</label><input type="text" value="cant touch this" readonly>';
const EmailTemplate = () => {
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [emailTemplateList, setEmailTemplateList] = useState("");
  const [eTempId, setETempId] = useState("");
  const [alert, setAlert] = useState(null);
  const [templateStatus, setTemplateStatus] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState("");
  const [modalType, setModalType] = useState();
  const toggle = () => setModal(!modal);
  const count = 0;
  const history = useHistory();
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    useTableProcessor: false,
    useTableResizer: false,
    buttons: [
      "source",
      "|",
      "bold",
      "strikethrough",
      "underline",
      "italic",
      "|",
      "ul",
      "ol",
      "|",
      "outdent",
      "indent",
      "|",
      "font",
      "fontsize",
      "brush",
      "paragraph",
      "|",
      "table",
      "superscript",
      "subscript",
      "|",
      "align",
      "undo",
      "redo",
      "|",
      "hr",
      "eraser",
      "copyformat",
      "|",
      "symbol",
    ],
  };

  useEffect(() => {
    setLoading(true);
    getAllEmailTemplates();
  }, []);

  const onAction = async (type) => {
    if (type === 1) {
      let data = { content: content };
      toggle();
      try {
        let res = await updateEmailTemplate(eTempId, data);
        if (res && res.status === 200) {
          toast.success("Updated Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          setLoading(true);
          setTimeout(() => {
            getAllEmailTemplates();
          }, 1000);
        }
      } catch (error) {
        if (error && error.response && error.response.status !== 500) {
          console.log(error);
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        } else {
          toast.error("Oops something went wrong...", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      }
    } else {
      toggle();
    }
  };

  const columns = [
    {
      name: "Sr No.",
      center: true,
      width: "100px",
      selector: (row) => row?.eTempId,
      sortable: true,
      // cell: (row, count) => {
      //   return ++count;
      // },
    },
    {
      name: "Template Name",
      selector: (row) => row?.eTempName,
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      center: true,
      selector: (row) => row?.eTempId,
      cell: (row) => {
        return (
          <div className="d-flex justify-content-center text-center m-1">
            <>
              <Button
                className="mr-2 btn-info btn-sm"
                onClick={() => displayModel(row?.eTempId, row?.content, "view")}
              >
                View
              </Button>
              <Button
                className="mr-2 btn-info btn-sm"
                onClick={() => displayModel(row?.eTempId, row?.content, "edit")}
              >
                Edit
              </Button>
              {/* <div>
                {row.isActive === true ? (
                  <label className="custom-toggle custom-toggle-success mr-1 ">
                    <input
                      defaultChecked
                      type="checkbox"
                      onChange={() =>
                        deactivateTemplate(row.eTempId, row.isActive)
                      }
                      disabled
                    />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      style={{
                        cursor: "not-allowed",
                        borderColor: "grey",
                      }}
                    />
                  </label>
                ) : (
                  <label className="custom-toggle custom-toggle-success mr-1">
                    <input
                      type="checkbox"
                      onChange={() =>
                        activateTemplate(row.eTempId, row.isActive)
                      }
                      disabled
                      className="disabled"
                    />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      style={{
                        cursor: "not-allowed",
                        borderColor: "grey",
                      }}
                    />
                  </label>
                )}
              </div> */}
            </>
          </div>
        );
      },
    },
  ];

  const getAllEmailTemplates = async () => {
    try {
      let response = await getTemplates({ template_type: 1 });
      if (response && response.status === 200) {
        setEmailTemplateList(response.data.data);
        setTotalRows(response.data.TotalResult);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setEmailTemplateList([]);
    }
  };

  // const activateTemplate = (eTempId, isActive) => {
  //   // setShowAlert(true);
  //   setETempId(eTempId);
  //   setTemplateStatus(true);
  //   setAlert(
  //     <ReactBSAlert
  //       warning
  //       style={{ display: "block", marginTop: "-100px" }}
  //       title="Are you sure?"
  //       onConfirm={() => onConfirmation(eTempId, isActive)}
  //       showCancel
  //       onCancel={() => onCancel()}
  //       confirmBtnBsStyle="secondary"
  //       confirmBtnText="Yes"
  //       cancelBtnBsStyle="danger"
  //       cancelBtnText="Cancel"
  //     >
  //       {isActive === true
  //         ? "You want to deactivate this Template!"
  //         : "You want to activate this Template!"}
  //     </ReactBSAlert>
  //   );
  // };

  // const deactivateTemplate = (eTempId, isActive) => {
  //   // setShowAlert(true);
  //   setETempId(eTempId);
  //   setTemplateStatus(false);
  //   setAlert(
  //     <ReactBSAlert
  //       warning
  //       style={{ display: "block", marginTop: "-100px" }}
  //       title="Are you sure?"
  //       onConfirm={() => onConfirmation(eTempId, isActive)}
  //       showCancel
  //       onCancel={() => onCancel()}
  //       confirmBtnBsStyle="secondary"
  //       confirmBtnText="Yes"
  //       cancelBtnBsStyle="danger"
  //       cancelBtnText="Cancel"
  //     >
  //       {isActive === true
  //         ? "You want to deactivate this Template!"
  //         : "You want to activate this Template!"}
  //     </ReactBSAlert>
  //   );
  // };

  const onCancel = () => {
    setAlert(null);
    setLoading(true);
    getAllEmailTemplates();
  };

  const onConfirmation = async (eTempId, isActive) => {
    let templateStatus = isActive === true ? false : true;
    let data = { is_active: templateStatus };

    let success_msg =
      isActive === true
        ? "Template Deactivated Successfully."
        : "Template Activated Successfully.";

    try {
      let res = await setEmailTemplateStatus(eTempId, data);
      if (res && res.status === 200) {
        toast.success(success_msg, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setLoading(true);
        setTimeout(() => {
          getAllEmailTemplates();
        }, 2000);
      }
    } catch (error) {
      if (error && error.response && error.response.status !== 500) {
        console.log(error);
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } else {
        toast.error("Oops something went wrong...", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    }

    setAlert(null);
  };

  const displayModel = async (eTempId, row, type) => {
    setETempId(eTempId);
    setModalData(row);
    // console.log(row);
    setContent(row);
    // setContent(
    //   '<a href="#" target="_blank"><img readOnly disabled src="http://52.14.207.41/static/logo/BLE_purple.png" alt="Konnektz" width="100%" height="auto" border="0" style="display: block; max-width: 250px; font-family: Open Sans, sans-serif;font-size: 18px; color: #fff; "></a>'
    // );

    setModalType(type);
    toggle();
  };

  function createMarkup() {
    return { __html: modalData };
  }

  return (
    <>
      {alert}
      <AlternativeHeader parentComponent="CMS" pageTitle="Email Template" />

      <Modal
        className="modal-dialog-centered"
        isOpen={modal}
        toggle={toggle}
        modalTransition={{ timeout: 100 }}
        scrollable={true}
        // fullscreen={true}
        size="xl"
        // dialogClassName="myModalStyle"
        style={{ width: "90%", maxWidth: "none" }}
      >
        <div className="modal-header p-2">
          {/* <h6 className="modal-title" id="modal-title-default">
            {modalData?.experience_description}
          </h6> */}
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggle}
          >
            <span aria-hidden={true} style={{ fontSize: "25px" }}>
              ×
            </span>
          </button>
        </div>
        <div className="modal-body p-2">
          <div className="justify-content-center text-center p-2" id="content">
            {modalType === "view" ? (
              <p dangerouslySetInnerHTML={createMarkup()} />
            ) : (
              // <Welcome />
              // modalData

              <>
                <JoditEditor
                  ref={editor}
                  value={content}
                  config={config}
                  tabIndex={1} // tabIndex of textarea
                  onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                  onChange={(newContent) => {}}
                />
                <div className="m-2">
                  <Button
                    className="m-2 btn btn-info"
                    onClick={() => onAction(1)}
                  >
                    Update
                  </Button>
                  <Button
                    className="m-2 btn btn-danger "
                    onClick={() => onAction(0)}
                  >
                    Cancel
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>

      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Email Template</h3>
              </CardHeader>
              <CardBody>
                <DataTable
                  columns={columns}
                  data={emailTemplateList}
                  progressPending={loading}
                  progressComponent={
                    <Spinner
                      as="span"
                      size="lg"
                      role="status"
                      aria-hidden="true"
                    />
                  }
                  //   theme="solarized"
                  striped
                  //   customStyles={customStyles}
                />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default EmailTemplate;
