import React from 'react'

import Map from 'views/pages/components/Map/Map';

import { QueryClient, QueryClientProvider } from "react-query";
const queryClient = new QueryClient();

export default function HitMap() {
  return (
    <QueryClientProvider client={queryClient}>
      {/* <Map/> */}
    </QueryClientProvider>
  )
}