import React, { useEffect, useState, useRef } from "react";
import AlternativeHeader from "components/Headers/AlternativeHeader";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Spinner,
  Modal,
} from "reactstrap";

import { Link } from "react-router-dom";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import moment from "moment";

import JoditEditor from "jodit-react";
import DataTable, { createTheme } from "react-data-table-component";
import {
  getAudioFetch,
  getAudioTemplates,
  updateAudio,
  deleteRecordingApi,
} from "services/services";
import { useHistory } from "react-router";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { toast } from "react-toastify";
import Welcome from "./EmailTemplates/welcome.jsx";
import "./modal.css";

import song from "./EmailTemplates/Suncrown - Legend of the Forgotten Centuries.mp3";
import Slider from "../components/slider/Slider";
import ControlPanel from "../components/controls/ControlPanel";
import RecordingCop from "./RecordingCop.js";

// const wel = require("./EmailTemplates/welcome.html");

// const wel =
//   '<h1>Hello, world!</h1><label disabled>hhhhhh</label><input type="text" value="cant touch this" readonly>';
const RecordingTemplate = () => {
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [emailTemplateList, setEmailTemplateList] = useState("");
  const [eTempId, setETempId] = useState("");
  const [alert, setAlert] = useState(null);
  const [templateStatus, setTemplateStatus] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState("");
  const [modalType, setModalType] = useState("");

  const [startDate, setStartDate] = useState(
    new Date("Thu Jun 02 2022 00:00:00 GMT+0530 (India Standard Time)")
  );
  const [dateData, setDateData] = useState("");
  const [responseData, setResponseData] = useState([]);
  const [timeError, setTimeError] = useState(true);
  const [audioTimeError, setAudioTimeError] = useState(true);

  const [languageData, setLanguageData] = useState("english");

  const [triggerTime, setTriggerTime] = useState(null)
  const history = useHistory();
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    useTableProcessor: false,
    useTableResizer: false,
    buttons: [
      "source",
      "|",
      "bold",
      "strikethrough",
      "underline",
      "italic",
      "|",
      "ul",
      "ol",
      "|",
      "outdent",
      "indent",
      "|",
      "font",
      "fontsize",
      "brush",
      "paragraph",
      "|",
      "table",
      "superscript",
      "subscript",
      "|",
      "align",
      "undo",
      "redo",
      "|",
      "hr",
      "eraser",
      "copyformat",
      "|",
      "symbol",
    ],
  };

  const [percentage, setPercentage] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [songApi, setSongApi] = useState(song);
  const [file, setFile] = useState([]);

  const [nameAudio, setNameAudio] = useState();

  const audioRef = useRef();

  const onChange = (e) => {
    const audio = audioRef.current;
    audio.currentTime = (audio.duration / 100) * e.target.value;
    setPercentage(e.target.value);
  };

  const play = () => {
    const audio = audioRef.current;
    audio.volume = 0.1;

    if (!isPlaying) {
      setIsPlaying(true);
      audio.play();
    }

    if (isPlaying) {
      setIsPlaying(false);
      audio.pause();
    }
  };

  const getCurrDuration = (e) => {
    const percent = (
      (e.currentTarget.currentTime / e.currentTarget.duration) *
      100
    ).toFixed(2);
    const time = e.currentTarget.currentTime;

    setPercentage(+percent);
    setCurrentTime(time.toFixed(2));
  };

  const toggle = () => {
    setModal(!modal);
    if (modal === false) {
      setPercentage(0);
      setIsPlaying(false);
      setDuration(0);
      setCurrentTime(0);
    }
  };

  // const [percentage, setPercentage] = useState(0)
  // const [isPlaying, setIsPlaying] = useState(false)
  // const [duration, setDuration] = useState(0)
  // const [currentTime, setCurrentTime] = useState(0)
  // const [songApi, setSongApi] = useState(song)

  useEffect(() => {
    setLoading(true);
    getAllRecordingTemplates();
  }, []);

  const handleName = (e) => {
    setNameAudio(e.target.value);
  };

  //here audio duration validation

  const getVideoDuration = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const media = new Audio(reader.result);
        media.onloadedmetadata = () => resolve(media.duration);
      };
      reader.readAsDataURL(file);
      reader.onerror = (error) => reject(error);
    });

  const handleFileSelected = async (e) => {
    const duration = await getVideoDuration(e.target.files[0]);
    console.log("duration", duration);
    if (duration > 30) {
      // setErrorRec("Warning Audio should be less than 30 seconds.");
      toast.error("Warning Audio should be less than 30 seconds.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      setAudioTimeError(false);
    } else if (duration < 30) {
      const filesx = Array.from(e.target.files);
      setFile(filesx);
      setAudioTimeError(true);
    }
  };

  const onAction = async (type) => {
    setLoading(true);
    if (type === 1) {
      if (audioTimeError === true) {
        if (timeError === true) {
          let data = new FormData();
          data.append("audio_file", file[0]);
          data.append("warning_audio_name", nameAudio);
          data.append("language", languageData);
          data.append("trigger_time", dateData);
          toggle();
          try {
            let res = await updateAudio(eTempId, data);
            if (res && res.status === 200) {
              toast.success("Updated Successfully", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });

              setTimeout(() => {
                getAllRecordingTemplates();
              }, 1000);
            }
          } catch (error) {
            if (error && error.response && error.response.status !== 500) {
              console.log(error);
              toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });
            } else {
              toast.error("Oops something went wrong...", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
              });
            }
          }
        } else if (timeError === false) {
          setLoading(false);
          toast.error(
            "Two warning recordings should have minimum gap of 5 minutes",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            }
          );
        }
      } else {
        setLoading(false);
        toast.error("Warning Audio should be less than 30 seconds.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    } else {
      toggle();
    }
  };

  let count = 1;
  const columns = [
    {
      name: "Sr No.",
      center: true,
      maxWidth: "5px",
      selector: (row) => count++,
      // sortable: true,
      // cell: (row, count) => {
      //   return ++count;
      // },
    },
    {
      name: "Audio Name",
      maxWidth: "250px",
      selector: (row) => row?.name,
      sortable: true,
      center: true,
    },
    {
      name: "Language",
      maxWidth: "10px",
      selector: (row) => row?.language,
      sortable: true,
      center: true,
    },
    {
      name: "Trigger Time",
      selector: (row) => row?.triggerTime,
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      center: true,
      maxWidth: "250px",
      selector: (row) => row?.id,
      cell: (row) => {
        return (
          <div className="d-flex justify-content-center text-center m-1">
            <>
              <Button
                className="mr-2 btn-info btn-sm"
                onClick={() =>
                  displayModel(
                    row?.id,
                    row?.audioFile,
                    "view",
                    row?.name,
                    row?.language,
                    row?.triggerTime
                  )
                }
              >
                Play
              </Button>

              <Button
                className="mr-2 btn-info btn-sm"
                onClick={() =>
                  displayModel(
                    row?.id,
                    row?.audioFile,
                    "edit",
                    row?.name,
                    row?.language,
                    row?.triggerTime
                  )
                }
              >
                {loading && (
                  <Spinner
                    as="span"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}{" "}
                Edit
              </Button>

              <Button
                className="mr-2 btn-info btn-sm"
                onClick={() => deleteRecording(row?.id)}
              >
                Delete
              </Button>
            </>
          </div>
        );
      },
    },
  ];

  const getAllRecordingTemplates = async () => {
    setLoading(true);
    try {
      let response = await getAudioTemplates({ template_type: 1 });
      console.log(response);
      if (response && response.status === 200) {
        setLoading(false);
        setEmailTemplateList(response.data.data);
        setTotalRows(response.data.TotalResult);
        setResponseData(response.data.data);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setEmailTemplateList([]);
    }
  };

  const deleteRecording = (id) => {
    console.log("here id", id);
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => onConfirmation(id)}
        showCancel
        onCancel={() => onCancel()}
        confirmBtnBsStyle="secondary"
        confirmBtnText="Yes"
        cancelBtnBsStyle="danger"
        cancelBtnText="No"
      ></ReactBSAlert>
    );
  };

  const onCancel = () => {
    setAlert(null);
    setLoading(true);
    getAllRecordingTemplates();
  };

  const onConfirmation = async (id) => {
    try {
      let res = await deleteRecordingApi(id);
      console.log("respo deleet", res);
      if (res && res.status === 200) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setLoading(true);
        setTimeout(() => {
          getAllRecordingTemplates();
        }, 2000);
      }
    } catch (error) {
      if (error && error.response && error.response.status !== 500) {
        toast.error(error.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } else {
        toast.error("Oops something went wrong...", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
    }

    setAlert(null);
  };

  const convertTimeToDesiredFormat = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    const currentDate = new Date();
    currentDate.setHours(hours);
    currentDate.setMinutes(minutes);
    currentDate.setSeconds(seconds);
    currentDate.setMilliseconds(0); // Clear milliseconds if needed
    return currentDate;
  };
  

  const displayModel = async (
    eTempId,
    row,
    type,
    name,
    language,
    trigger_time
  ) => {
    setETempId(eTempId);
    setModalData(row);
    setContent(row);
    setNameAudio(name);
    if (trigger_time) {
      const formattedDate = convertTimeToDesiredFormat(trigger_time);
      setStartDate(formattedDate);
    }
    setTriggerTime(trigger_time)
    setDateData(trigger_time)
    setModalType(type);
    toggle();
  };

  function timeTigger(date) {
    const hms = moment(date).format("HH:mm:ss");
    setDateData(hms);
    const [hours, minutes] = hms.split(":");
    const totalSeconds = +hours * 60 * 60 + +minutes * 60;

    // let arrTime=['05:40','01:01']
    if (hms == triggerTime) {
      setTimeError(true)
    } else {
      for (let i = 0; i < responseData?.length; i++) {
        console.log("time dis", responseData[i].triggerTime);
        const hmsArr = responseData[i]?.triggerTime;
        const [hoursarr, minutesarr] = hmsArr.split(":");
        const totalSecondsarr = +hoursarr * 60 * 60 + +minutesarr * 60;
  
        if (
          totalSeconds > totalSecondsarr - 300 &&
          totalSeconds < totalSecondsarr + 300
        ) {
          console.log("error data");
          setTimeError(false);
          break;
        } else {
          console.log("upload data");
          setTimeError(true);
        }
      }
    }
  }

  function createMarkup() {
    return { __html: modalData };
  }

  // const addRecording = () => {
  //   let path = `/admin/customerdetails/add`;
  //   history.push(path);
  // };

  return (
    <>
      {alert}
      <AlternativeHeader parentComponent="CMS" pageTitle="Warning Audio" />

      <Modal
        className="modal-dialog-centered w-50"
        isOpen={modal}
        toggle={toggle}
        modalTransition={{ timeout: 100 }}
        scrollable={true}
        // fullscreen={true}
        size="xl"
        // dialogClassName="myModalStyle"
        style={{ width: "90%", maxWidth: "none" }}
      >
        <div className="modal-header p-2">
          {/* <h6 className="modal-title" id="modal-title-default">
            {modalData?.experience_description}
          </h6> */}
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggle}
          >
            <span aria-hidden={true} style={{ fontSize: "25px" }}>
              ×
            </span>
          </button>
        </div>
        <div className="modal-body p-2">
          <div className="justify-content-center text-center p-2" id="content">
            {modalType === "view" ? (
              <RecordingCop cmp={eTempId} />
            ) : (
              <>
                <Row>
                  <Col className="mb-3" md="6">
                    <label
                      className="form-control-label"
                      htmlFor="validationCustom07"
                    >
                      Warning Audio Name
                    </label>
                    <Input
                      className="custom_file_input"
                      id="validationCustom07"
                      type="text"
                      value={nameAudio}
                      onChange={handleName}
                    />
                  </Col>

                  <Col className="mb-3" md="6">
                    <label className="form-control-label" htmlFor="vid">
                      Warning Audio File
                    </label>
                    <Input
                      className="custom_file_input"
                      id="vid"
                      type="file"
                      accept=".mp3"
                      onChange={handleFileSelected}
                    />
                  </Col>

                  <Col className="mb-3" md="6">
                    <label
                      className="form-control-label"
                      htmlFor="validationCustom07"
                    >
                      Trigger Time
                    </label>
                    <DatePicker
                      className="form-control"
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date);
                        timeTigger(date);
                      }}
                      timeFormat="HH:mm:ss"
                      timeCaption="time"
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={1}
                      dateFormat="HH:mm:ss"
                      // onClick={timeTigger()}
                    />
                  </Col>

                  {/* <Col className="mb-3" md="6">
                    <label
                      className="form-control-label"
                      htmlFor="validationCustom01"
                    >
                      Language
                    </label>
                    <Input
                      type={"select"}
                      onChange={(e) => setLanguageData(e.target.value)}
                    >
                      <option value={"english"}>English</option>
                      <option value={"hindi"}>Hindi</option>
                    </Input>
                  </Col> */}
                  <Col className="mb-3" md="12">
                    <Button
                      className="m-2 btn btn-info"
                      onClick={() => onAction(1)}
                    >
                      Change
                    </Button>
                  </Col>

                  {/* <Button
                    className="m-2 btn btn-danger "
                    onClick={() => onAction(0)}
                  >
                    Cancel
                  </Button> */}
                </Row>
              </>
            )}
          </div>
        </div>
      </Modal>

      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <Row className="mb-3">
                  <Col md="4">
                    <h1>Warning Audio</h1>
                  </Col>
                  <Col md="8">
                    <div className="d-flex justify-content-end align-items-center flex-wrap">
                      <Link
                        type="button"
                        className="btn btn-info mx-2"
                        to="/admin/addrecording"
                      >
                        Add Audio
                      </Link>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <DataTable
                  columns={columns}
                  data={emailTemplateList}
                  progressPending={loading}
                  progressComponent={
                    <Spinner
                      as="span"
                      size="lg"
                      role="status"
                      aria-hidden="true"
                    />
                  }
                  //   theme="solarized"
                  striped
                  //   customStyles={customStyles}
                />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default RecordingTemplate;
