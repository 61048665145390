// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

function ProfileHeader(props) {
  return (
    <>
      <div
        className="header pb-2 d-flex align-items-center"
        style={{
          minHeight: "250px"
        }}
      >
        <span className="mask bg-gradient-info" />

        <Container fluid>
          <Row>
            <Col md="12">
              <h1 className="display-2 text-white">Hello {props.name},</h1>
              <p className="text-white mt-0 mb-5"> Welcome to your profile</p>
              <div className="d-flex justify-content-between w-100">
                <Button className="btn-neutral" color="default" onClick={props.onEdit}>Edit profile</Button>
                <Button className="" color="default" onClick={props.onChangePassword}>Change Password</Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ProfileHeader;
