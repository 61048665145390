import "./EmailTemplates.css";

const Welcome = () => {
  return (
    // <h1>dhasjkdaskdn.</h1>
    <body
      className="body"
      style={{
        padding: "0",
        margin: "0 auto !important",
        display: "block !important",
        minWidth: "100% !important",
        width: "100% !important",
        background: "#f2f2f2",
      }}
    >
      <span
        className="mcnPreviewText"
        style={{
          display: "none",
          fontSize: "0px",
          lineHeight: "0px",
          maxHeight: "0px",
          maxWidth: "0px",
          opacity: "0",
          overflow: "hidden",
          visibility: "hidden",
          msoHide: "all",
        }}
      >
        Welcome to Konnektz .....
      </span>

      <table
        width="100%"
        border="0"
        cellspacing="0"
        cellpadding="0"
        style={{ background: "#f2f2f2" }}
        className="full-wrap"
      >
        <tr>
          <td align="center" valign="top">
            <table
              align="center"
              style={{
                width: "600px",
                maxWidth: "600px",
                tableLayout: "fixed",
                background: "#022383",
              }}
              className="oc_wrapper"
              width="600"
              border="0"
              cellspacing="0"
              cellpadding="0"
            >
              <tr>
                <td
                  align="center"
                  valign="top"
                  style={{ padding: "20px 20px 20px;" }}
                >
                  <table
                    border="0"
                    cellspacing="0"
                    cellpadding="0"
                    align="center"
                  >
                    <tr>
                      <td align="center" valign="top">
                        <a href="#" target="_blank">
                          <img
                            src="http://52.14.207.41/static/logo/BLE_purple.png"
                            alt="Konnektz"
                            width="100%"
                            height="auto"
                            border="0"
                            style={{
                              display: "block",
                              maxWidth: "250px",
                              fontFamily: "Open Sans, sans-serif",
                              fontSize: "18px",
                              color: "#fff;",
                            }}
                          />
                        </a>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>

      <table
        width="100%"
        border="0"
        cellspacing="0"
        cellpadding="0"
        style={{ background: "#f2f2f2;" }}
        className="full-wrap"
      >
        <tr>
          <td align="center" valign="top">
            <table
              align="center"
              style={{
                width: "600px",
                maxWidth: "600px",
                tableLayout: "fixed;",
              }}
              className="oc_wrapper"
              width="600"
              border="0"
              cellspacing="0"
              cellpadding="0"
            >
              <tr>
                <td
                  align="center"
                  valine="top"
                  style={{ backgroundColor: "#ffffff;" }}
                >
                  <table
                    width="600"
                    border="0"
                    cellspacing="0"
                    cellpadding="0"
                    align="center"
                    style={{ width: "600px;" }}
                    className="oc_wrapper"
                  >
                    <tbody>
                      <tr>
                        <td
                          align="center"
                          valine="top"
                          style={{
                            padding: " 10px 15px 30px",
                            background: "#ffffff;",
                          }}
                        >
                          <table
                            width="100%"
                            border="0"
                            cellspacing="0"
                            cellpadding="0"
                            align="center"
                            style={{ width: "100%" }}
                          >
                            <tbody>
                              <tr>
                                <td
                                  align="center"
                                  valign="top"
                                  style={{
                                    fontFamily: " Open Sans, sans-serif",
                                    fontSize: "24px",
                                    lineHeight: "30px",
                                    color: "#197dce",
                                    textAlign: "center",
                                    fontWeight: "700;",
                                  }}
                                >
                                  Your account was created by your
                                  administrator.
                                </td>
                              </tr>
                              <tr>
                                <td
                                  align="left"
                                  valign="top"
                                  style={{
                                    fontFamily: " Open Sans, sans-serif",
                                    fontSize: "14px",
                                    lineHeight: "18px",
                                    color: "#000000",
                                    paddingTop: " 15px",
                                    textAlign: "left;",
                                  }}
                                >
                                  Dear{"  "}
                                  <span style={{ fontWeight: "700;" }}>
                                    <input
                                      type="text"
                                      placeholder="username"
                                      readOnly
                                      style={{ border: "none" }}
                                    />
                                  </span>
                                  ,
                                </td>
                              </tr>
                              <tr>
                                <td
                                  align="left"
                                  valign="top"
                                  style={{
                                    fontFamily: " Open Sans, sans-serif",
                                    fontSize: "14px",
                                    lineHeight: "20px",
                                    color: "#000000",
                                    paddingTop: " 15px",
                                    textAlign: "left;",
                                  }}
                                >
                                  Welcome to Konnektz. <br /> <br />
                                  To access your account, use the credentials
                                  below: <br />
                                  <br />
                                  <br />
                                  Email : {"email"}
                                  <br />
                                  Password : {"password"}
                                  {/* <br />
                                  Click here to{" "}
                                  <a
                                    href="{{link}}"
                                    target="_blank"
                                    style={{
                                      textDecoration: "underline",
                                      color: "#197dce;",
                                    }}
                                  >
                                    Login{" "}
                                  </a> */}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>

      <table
        width="100%"
        border="0"
        cellspacing="0"
        cellpadding="0"
        style={{ background: "#f2f2f2;" }}
        className="full-wrap"
      >
        <tr>
          <td align="center">
            <table
              align="center"
              style={{
                width: "600px",
                maxWidth: "600px",
                tableLayout: "fixed;",
                background: "#ffffff",
              }}
              className="oc_wrapper"
              width="600"
              border="0"
              cellspacing="0"
              cellpadding="0"
            >
              <tr>
                <td
                  align="center"
                  style={{ padding: "15px", borderTop: "1px solid #ececec;" }}
                >
                  <table
                    align="center"
                    style={{ width: "100%;" }}
                    border="0"
                    cellspacing="0"
                    cellpadding="0"
                  >
                    {/* <tr>
                      <td
                        align="left"
                        valign="top"
                        style={{
                          fontFamily: "Open Sans, sans-serif",
                          fontSize: "12px",
                          lineHeight: "15px",
                          color: "#000000",
                          display: "none;",
                        }}
                      >
                        By signing with Konnektz you agree with our Terms of Use
                        and Privacy Policy. Please read these policies before
                        start to use our services. For any question regarding
                        our policies, please contact us by sending an e-mail to
                        <a
                          href="mailto:legal@vyzeo.com"
                          target="_blank"
                          style={{
                            textDecoration: "underline",
                            color: "#197dce;",
                          }}
                        >
                          legal@vyzeo.com
                        </a>
                        .
                      </td>
                    </tr> */}
                    <tr>
                      <td
                        align="left"
                        valign="top"
                        style={{
                          paddingTop: "15px",
                          fontFamily: " Open Sans, sans-serif",
                          fontSize: "12px",
                          lineHeight: "18px",
                          color: "#000000;",
                        }}
                      >
                        {/* <a
                          href="https://vyzeo.oneclickitmarketing.co.in/terms-of-use"
                          target="_blank"
                          style={{
                            textDecoration: "underline",
                            color: "#197dce;",
                          }}
                        >
                          Terms of Use
                        </a>
                        |
                        <a
                          href="https://vyzeo.oneclickitmarketing.co.in/privacy-policy"
                          target="_blank"
                          style={{
                            textDecoration: "underline",
                            color: "#197dce;",
                          }}
                        >
                          Privacy Policy
                        </a>
                        |
                        <a
                          href="#"
                          target="_blank"
                          style={{
                            textDecoration: "underline",
                            color: "#197dce;",
                          }}
                        >
                          Unsubscribe
                        </a>
                        <br /> */}
                        <tr>
                          <td
                            align="center"
                            valign="top"
                            style={{
                              paddingTop: "8px",
                              fontFamily: " 'Open Sans', sans-serif",
                              fontSize: "12px",
                              lineHeight: "15px",
                              color: "#000000",
                              fontWeight: "700",
                            }}
                          >
                            &copy; 2021 Konnektz All rights reserved.
                          </td>
                        </tr>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </body>
  );
};

export default Welcome;
