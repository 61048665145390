import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getStaticPageData } from "services/services";

const ViewStaticPage = (props) => {
  const [data, setData] = useState();
  let { pageId } = useParams();
  useEffect(() => {
    const id = props.location.pathname.substring(
      props.location.pathname.lastIndexOf("/") + 1
    );
    getStaticPage(id);
  }, []);

  const getStaticPage = async (id) => {
    try {
      let response = await getStaticPageData(id);
      console.log(response?.data?.data?.content);
      setData(response?.data?.data?.content);
    } catch (err) {}
  };

  function createMarkup() {
    return { __html: data };
  }

  return (
    <p
      style={{ background: "white", height: "100vh" }}
      dangerouslySetInnerHTML={createMarkup()}
    />
  );
};

export default ViewStaticPage;
