import React, { useEffect, useState } from "react";
import AlternativeHeader from "components/Headers/AlternativeHeader";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Spinner,
} from "reactstrap";
import DataTable from "react-data-table-component";
import {
  getEmergencyContact,
  updateUserStatus,
  exportEmergencyContact,
} from "services/services";
import { useHistory } from "react-router";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { toast } from "react-toastify";
import { CSVLink, CSVDownload } from "react-csv";

export default function EmergencyContact() {
  const [perPage, setPerPage] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [contactList, setcontactList] = useState("");
  const [selectStatus, setSelectStatus] = useState("1");
  const [userId, setUserId] = useState("");
  const [userStatus, setUserStatus] = useState(false);
  const [alert, setAlert] = useState(null);
  const [exportcontactList, setExportcontactList] = useState("");

  const history = useHistory();

  useEffect(() => {

    let params = {
      limit: perPage,
      page_no: pageNo,
      keyword: searchInput,
      active_status: selectStatus,
    };
  
    getAllContact(params);

    let dataContact = { active_status: selectStatus, keyword: searchInput };
    exportContact(dataContact)
  }, []);

  //   const onEdit = (userId) => {
  //     history.push({
  //       pathname: "/admin/customerdetails/" + userId,
  //     });
  //   };

  let count = 1;

  const columns = [
    {
      name: "S. No.",
      selector: (row, index) => (pageNo - 1) * perPage + index + 1,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row?.contactName,
      sortable: true,
    },
    {
      name: "Email",
      minWidth: "250px",
      selector: (row) => row?.contactEmail,
      sortable: true,
      compact: true,
    },
    {
      name: "CountryCode",
      selector: (row) => row?.countryCode,
      sortable: true,
    },
    {
      name: "Number",
      selector: (row) => row?.contactNumber,
      sortable: true,
    },
    {
      name: "Related to User",
      selector: (row) => row?.userContacts?.userName,
      sortable: true,
    },

    {
      name: "Is Deleted",
      selector: (row) => row?.isDeleted,
      sortable: true,
      cell: (row) => {
        return (
          <div>
            {row?.isDeleted === true ? <span>Yes</span> : <span>No</span>}
          </div>
        );
      },
    },
    {
      name: "Consent status",
      selector: (row) => row?.consentStatus,
      sortable: true,
      cell: (row) => {
        return (
          <div>
            {row?.consentStatus === 1 ? <span class="badge badge-warning" style={{color: "orange", fontWeight: "bold", fontSize: "11px", padding: "9px"}}>Pending</span> : row?.consentStatus === 2 ? <span class="badge badge-success" style={{color: "green", fontWeight: "bold", fontSize: "11px", padding: "9px"}}>Approved</span> : row?.consentStatus === 3 ? <span class="badge badge-danger" style={{color: "red", fontWeight: "bold", fontSize: "11px", padding: "9px"}}>Declined</span> : "N/A"}
          </div>
        );
      },
    },
    {
      name: "Status",
      selector: (row) => row?.isActive,
      sortable: true,
      cell: (row) => {
        return (
          <div>
            {row?.isActive === true ? <span>Active</span> : <span>Inactive</span>}
          </div>
        );
      },
    },
    // {
    //   name: "Created Date",
    //   maxWidth: "200px",
    //   center: true,
    //   selector: (row) => row.createdDate,
    //   sortable: true,
    //   cell: (row) => {
    //     if (row && row.createdDate) {
    //       return (
    //         <div className="d-flex justify-content-center">
    //           {new Date(row.createdDate).toLocaleDateString("en-GB", {
    //             year: "numeric",
    //             month: "short",
    //             day: "numeric",
    //           })}
    //         </div>
    //       );
    //     }
    //   },
    // },
    // {
    //   name: "Register via",
    //   maxWidth: "150px",
    //   center: true,
    //   selector: (row) => row.accountType,
    //   sortable: true,
    //   cell: (row) => {
    //     if (row.accountType === 1) return "EMAIL";
    //     else if (row.accountType === 2) return "FACEBOOK";
    //     else if (row.accountType === 3) return "GOOGLE";
    //     else return "APPLE";
    //   },
    // },
    // {
    //   maxWidth: "150px",
    //   center: true,
    //   name: "Status",
    //   selector: (row) => row?.isDeleted,
    //   cell: (row) => {
    //     return (
    //       <div>
    //         {row?.isDeleted === 1 ? (
    //           <label className="custom-toggle custom-toggle-success">
    //             <input
    //               defaultChecked
    //               type="checkbox"
    //               onChange={() => activateUser(row.userId, row.status)}
    //             />
    //             <span className="custom-toggle-slider rounded-circle" />
    //           </label>
    //         ) : (
    //           <label className="custom-toggle custom-toggle-success">
    //             <input
    //               type="checkbox"
    //               onChange={() => deactivateUser(row.userId, row.status)}
    //             />
    //             <span className="custom-toggle-slider rounded-circle" />
    //           </label>
    //         )}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   center: true,
    //   name: "Action",
    //   maxWidth: "120px",
    //   cell: (row) => {
    //     return (
    //       <div className="d-flex justify-content-center">
    //         <>
    //           <Button
    //             className="mr-2 btn-primary"
    //             onClick={() => onEdit(row.userId)}
    //           >
    //             <i className="fa fa-edit" />
    //           </Button>
    //         </>
    //       </div>
    //     );
    //   },
    // },
  ];

  const getAllContact = async (params) => {
    setLoading(true)
    console.log("let see change", searchInput);
    try {
      let response = await getEmergencyContact(params);
      console.log("search ", response);
      if (response && response.status === 200) {
        setcontactList(response?.data?.data);
        setTotalRows(response.data?.totalResult);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setcontactList();
    }
  };

  const onSearch = async (e) => {
    console.log("here change ", e.target.value);
    setPageNo(1)
    setSearchInput(e.target.value);
    let dataContact = { active_status: selectStatus, keyword: e.target.value };
    exportContact(dataContact)
    if (e.target.value.length > 2) {
      let params = {
        limit: perPage,
        page_no: 1,
        keyword: e.target.value,
        active_status: selectStatus,
      };
      getAllContact(params);
    } else {
      let params = {
        limit: perPage,
        page_no: 1,
        keyword: e.target.value,
        active_status: selectStatus,
      };
      getAllContact(params);
    }
  };

  const handlePageChange = (page) => {
    setLoading(true);
    setPageNo(page)
    let params = {
      limit: perPage,
      page_no: page,
      keyword: searchInput,
      active_status: selectStatus,
    };
    getAllContact(params);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    let params = {
      limit: newPerPage,
      page_no: page,
      keyword: searchInput,
      active_status: selectStatus,
    };
    getAllContact(params);
    setPerPage(newPerPage);
  };

  const handleSelect = (e) => {
    setLoading(true);
    setPageNo(1)
    let params = {
      limit: perPage,
      page_no: 1,
      keyword: searchInput,
      active_status: e.target.value,
    };
    console.log("here slect", params);
    getAllContact(params);
    setSelectStatus(e.target.value);
    let dataContact = { active_status: e.target.value, keyword: searchInput };
    exportContact(dataContact)
  };

  const exportContact = async (dataContact) => {
   
    try {
      let response = await exportEmergencyContact(dataContact);
      console.log("here res ", response);
      if (response && response.status === 200) {
        setExportcontactList(response.data);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <>
      {alert}
      <AlternativeHeader pageTitle="Emergency Contact" parentComponent="" />

      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Emergency Contact</h3>
              </CardHeader>
              <CardBody>
                <Row className="mb-3">
                  <Col md="3">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fa fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Search"
                        name="searchInput"
                        type="text"
                        onChange={onSearch}
                        autoComplete="off"
                        value={searchInput || ""}
                      />
                    </InputGroup>
                  </Col>
                  <Col md="9">
                    <div className="d-flex justify-content-end align-items-center flex-wrap">
                      <Row className="mb-1">
                        <Col md="6">
                          <Input
                            type="select"
                            name="selectStatus"
                            onChange={handleSelect}
                            id="exampleSelect"
                          >
                            <option value="1">All</option>
                            <option value="3">Active</option>
                            <option value="2">Inactive</option>
                          </Input>
                        </Col>
                        <Col md="6">
                          <CSVLink
                            data={exportcontactList}
                            filename={"EmergencyContact.csv"}
                            className="btn btn-info"
                            
                          >
                            Export
                          </CSVLink>
                          
                          {/* <Button type="button" className="btn btn-info mx-2" onClick={exportContact}> 
                          </Button> */}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <DataTable
                  columns={columns}
                  data={contactList}
                  progressPending={loading}
                  progressComponent={
                    <Spinner
                      as="span"
                      size="lg"
                      role="status"
                      aria-hidden="true"
                    />
                  }
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
                  striped
                />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
