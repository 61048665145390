import AlternativeHeader from "components/Headers/AlternativeHeader";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import { getUserById, createUser, updateUserById } from "services/services";
import { validatePassword, validateEmail } from "shared/validation";

import PhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/bootstrap.css";

const CustomerDetails = (props) => {
  const [disabled, setDisabled] = useState(false);
  const [errors, setErrors] = useState(false);
  const [addEdit, setAddEdit] = useState();
  const [userId, setUserId] = useState();

  // const [focused, setFocused] = useState(false);
  const [accountType, setAccountType] = useState("");
  const [fullName, setfullName] = useState("");
  const [fullNameState, setfullNameState] = useState(null);
  const [userName, setUserName] = useState("");
  const [usernameState, setUserNameState] = useState(null);
  // const [disableField, setDisableField] = useState("");

  const [emailId, setEmailId] = useState("");
  const [emailIdState, setEmailIdState] = useState(null);
  const [password, setPassword] = useState("");
  const [passwordState, setPasswordState] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordState, setConfirmPasswordState] = useState(null);

  const [phone, setPhone] = useState("");
  const [phoneState, setPhoneState] = useState(null);

  const [codeCountry, setCodeCountry] = useState("");
  const [codeCountryState, setCodeCountryState] = useState(null);

  const [profilePic, setProfilePic] = useState("");
  const [profilePicURL, setProfilePicURL] = useState("");
  const [profilePicName, setProfilePicName] = useState("");

  //   const [selectedImage, setSelectedImage] = useState();
  const history = useHistory();

  useEffect(() => {
    const userId = props.location.pathname.substring(
      props.location.pathname.lastIndexOf("/") + 1
    );
    if (userId !== "add") {
      setUserId(userId);
      setAddEdit("edit");
      getUserInfo(userId);
    } else {
      // console.log("here add clg ")
      setAddEdit("add");
    }
  }, []);

  const getUserInfo = async (userId) => {
    const id = userId;
    try {
      let res = await getUserById(id);
      console.log("res ", res);
      if (res && res.status === 200) {
        setAccountType(res.data.data.accountType);
        setfullName(res.data.data.fullName);
        setEmailId(res.data.data.email);
        setUserName(res.data.data.userName);
        setPhone(`${res.data.data.countryCode} ${res.data.data.phoneNumber}`);
        setProfilePicURL(res.data.data.profilePic);
        setProfilePicName(res.data.data.profilePic);
        // setProfilePic(url);
        // setDisableField(disabled);
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      history.replace({ pathname: "/admin/customer" });
    }
  };

  const handleChange = (e) => {
    let error;
    if (e.target.name === "email") {
      error = validateEmail(e.target.value);
      if (error) setEmailIdState("invalid");
    } else if (e.target.name === "password") {
      error = validatePassword(e.target.value);
      if (error || e.target.value === "") setPasswordState("invalid");
    } else if (e.target.name === "confirm_password") {
      if (e.target.value !== password) {
        error = "Password Not matched";
        if (error) setConfirmPasswordState("invalid");
      }
    } else if (e.target.name === "username") {
      if (e.target.value === "") {
        error = "Enter username";
        setUserNameState("invalid");
      }
    } else if (e.target.name === "fullName") {
      if (e.target.value === "") {
        error = "Enter Full Name";
        setfullNameState("invalid");
      }
    }
    else if (e.target.name === "phone") {
      if (e.target.value === "") {
        error = "Enter Phone Number";
        setPhoneState("invalid");
      }
    }
  };

  const onCancel = () => {
    let path = `/admin/customer`;
    history.push(path);
  };

  const handelSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);
    const id = userId;
    // let data = new FormData();
    // data.append("full_name", fullName);
    // data.append("email", emailId);
    // data.append("phone_no", 8866123566);
    // data.append("country_code", 91);
    // data.append("profile_pic", profilePic);

    let data = {
      full_name: fullName,
      email: emailId,
      phone_no: phone,
      country_code: codeCountry,
      password: password,
      confirm_password: confirmPassword,
    };

    if (!errors) {
      try {
        let res = await updateUserById(id, data);
        if (res && res.status === 200) {
          toast.success("User updated successfully.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          setTimeout(() => {
            setDisabled(false);
            props.history.replace({
              pathname: "/admin/customer",
              search: props.location.search,
            });
          }, 500);
        }
      } catch (err) {
        setDisabled(true);
        if (err.response.status !== 500) {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        } else {
          toast.error("Oops something went wrong...", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      }
    }
  };

  const handelCreate = async (e) => {
    e.preventDefault();
    setDisabled(true);
    /* let data = new FormData();
    data.append("full_name", fullName);
    data.append("user_name", userName);
    data.append("email", emailId);
    data.append("password", password);
    data.append("confirm_password", confirmPassword);
    data.append("phone_no", 8866114422);
    data.append("country_code", 1); */
    // console.log("data api ",fullName)
    // console.log("data api ",userName)

    let data = {
      full_name: fullName,
      user_name: userName,
      email: emailId,
      password: password,
      confirm_password: confirmPassword,
      phone_no: phone,
      country_code: codeCountry,
    };

    console.log("here data", data);

    // data.append("profile_pic", profilePic);
    if (!errors) {
      try {
        let res = await createUser(data);
        if (res && res.status === 200) {
          toast.success("User created successfully.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          setTimeout(() => {
            setDisabled(false);
            props.history.replace({
              pathname: "/admin/customer",
              search: props.location.search,
            });
          }, 500);
        }
      } catch (err) {
        setDisabled(false);
        if (err.response.status !== 500) {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        } else {
          toast.error("Oops something went wrong...", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      }
    }
  };

  const validateCustomStylesForm = () => {
    if (fullName === "") {
      setfullNameState("invalid");
    }
    if (userName === "") {
      setUserNameState("invalid");
    }
    if (emailId === "") {
      setEmailIdState("invalid");
    }
    if (addEdit === "add") {
      if (password === "") {
        setPasswordState("invalid");
        setErrors(true);
      }
      if (confirmPassword === "") {
        setConfirmPasswordState("invalid");
        setErrors(true);
      }
    } else {
      setPasswordState("valid");
      setConfirmPasswordState("valid");
    }
    if (fullName === "" || userName === "" || emailId === "") {
      setErrors(true);
    }
  };

  const onChangeProfile = (e) => {
    setProfilePic(e.target.files[0]);
    setProfilePicName(e.target.files[0].name);
    setProfilePicURL(URL.createObjectURL(e.target.files[0]));
  };

  // console.log("her acc  type", accountType)
  return (
    <>
      <AlternativeHeader
        parentComponent="User Management"
        pageTitle={addEdit === "edit" ? "Edit User" : "Add User"}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">
                  {addEdit === "edit" ? "Edit User" : "Add User"}
                </h3>
              </CardHeader>
              <CardBody>
                <Form
                  className="needs-validation"
                  noValidate
                  onSubmit={addEdit === "edit" ? handelSubmit : handelCreate}
                >
                  <div className="form-row">
                    <Col className="mb-3" md="6">
                      <label
                        className="form-control-label"
                        htmlFor="validationCustom01"
                      >
                        Full name
                      </label>
                      <Input
                        id="validationCustom01"
                        placeholder="Full name"
                        type="text"
                        valid={fullNameState === "valid"}
                        invalid={fullNameState === "invalid"}
                        name="fullName"
                        onChange={(e) => {
                          setfullName(e.target.value);
                          if (e.target.value === "") {
                            setfullNameState("invalid");
                          } else {
                            setfullNameState("valid");
                          }
                        }}
                        value={fullName}
                      />
                      <div className="invalid-feedback">
                        Please Enter Full Name.
                      </div>
                    </Col>

                    <Col className="mb-3" md="6">
                      <label
                        className="form-control-label"
                        htmlFor="validationCustom03"
                      >
                        Email Id
                      </label>
                      {/* {addEdit === "edit" ? (
                        <Input
                          placeholder="Email id"
                          type="email"
                          value={emailId}
                          disabled
                        />
                      ) : (
                        <Input
                          id="validationCustom03"
                          placeholder="Email Id"
                          name="email"
                          type="email"
                          valid={emailIdState === "valid"}
                          invalid={emailIdState === "invalid"}
                          onChange={(e) => {
                            setEmailId(e.target.value);
                            if (e.target.value === "") {
                              setEmailIdState("invalid");
                            } else {
                              setEmailIdState("valid");
                            }
                            handleChange(e);
                          }}
                          value={emailId}
                        />
                      )} */}

                      <Input
                        id="validationCustom03"
                        placeholder="Email Id"
                        name="email"
                        type="email"
                        valid={emailIdState === "valid"}
                        invalid={emailIdState === "invalid"}
                        onChange={(e) => {
                          setEmailId(e.target.value);
                          if (e.target.value === "") {
                            setEmailIdState("invalid");
                          } else {
                            setEmailIdState("valid");
                          }
                          handleChange(e);
                        }}
                        value={emailId}
                      />
                      <div className="invalid-feedback">
                        Please Enter valid Email Id.
                      </div>
                    </Col>
                  </div>

                  <div className="form-row">
                    <Col className="mb-3" md="6">
                      <label
                        className="form-control-label"
                        htmlFor="validationCustomUsername"
                      >
                        Username
                      </label>
                      {addEdit === "edit" ? (
                        <Input
                          placeholder="username"
                          type="text"
                          value={userName}
                          disabled
                        />
                      ) : (
                        <Input
                          id="validationCustomUsername"
                          placeholder="Username"
                          type="text"
                          valid={usernameState === "valid"}
                          invalid={usernameState === "invalid"}
                          onChange={(e) => {
                            setUserName(e.target.value);
                            if (e.target.value === "") {
                              setUserNameState("invalid");
                            } else {
                              setUserNameState("valid");
                            }
                          }}
                          value={userName}
                        />
                      )}
                      <div className="invalid-feedback">
                        Please choose a username.
                      </div>
                    </Col>

                    {/* <Col className="mb-3" md="2">
                      <label
                        className="form-control-label"
                        htmlFor="validationCustom015"
                      >
                        Country code
                      </label>
                      <Input
                      id="validationCustom015"
                        type="select"
                        name="select"
                        valid={codeCountryState === "valid"}
                        invalid={codeCountryState === "invalid"}
                        onChange={(e) => {
                          setCodeCountry(e.target.value);
                          if (e.target.value === "") {
                            setCodeCountryState("invalid");
                          } else {
                            setCodeCountryState("valid");
                          }
                        }}
                        value={codeCountry}
                      >
                        <option value="91">+91 - India</option>
                        <option value="93">+93 - Afghanistan</option>
                      </Input>
                      <div className="invalid-feedback">
                        Please Enter Country code.
                      </div>
                    </Col> */}

                    {/* <Col className="mb-3" md="4">
                      <label
                        className="form-control-label"
                        htmlFor="validationCustom01"
                      >
                        Contact Number
                      </label>
                      <Input
                        id="validationCustom01"
                        placeholder="Phone Number"
                        type="number"
                        valid={phoneState === "valid"}
                        invalid={phoneState === "invalid"}
                        name="phone"
                        onChange={(e) => {
                          setPhone(e.target.value);
                          if (e.target.value === "") {
                            setPhoneState("invalid");
                          } else {
                            setPhoneState("valid");
                          }
                        }}
                        value={phone}
                      />
                      <div className="invalid-feedback">
                        Please Enter Phone Number.
                      </div>
                    </Col> */}

                    <Col className="mb-3" md="6">
                      <label
                        className="form-control-label"
                        htmlFor="validationCustom01"
                      >
                        Contact Number
                      </label>
                      <PhoneInput
                        id="validationCustom01"
                        country={"in"}
                        // flagComponent
                        inputStyle={{ width: "100%" }}
                        name="phone"
                        onChange={setPhone}
                        value={phone}
                      />
                    </Col>
                  </div>

                  <div className="form-row">
                    <Col className="mb-3" md="6">
                      <label
                        className="form-control-label"
                        htmlFor="validationCustom04"
                      >
                        Password
                      </label>
                      {/* {addEdit === "edit" ? (
                        <Input
                          placeholder="Password"
                          type="password"
                          value="********"
                          disabled
                        />
                      ) : (
                        <Input
                          id="validationCustom04"
                          placeholder="Password"
                          name="password"
                          type="password"
                          valid={passwordState === "valid"}
                          invalid={passwordState === "invalid"}
                          onChange={(e) => {
                            setPassword(e.target.value);
                            if (e.target.value === "") {
                              setPasswordState("invalid");
                            } else {
                              setPasswordState("valid");
                            }
                            handleChange(e);
                          }}
                          value={password}
                        />
                      )} */}
                      <Input
                        id="validationCustom04"
                        placeholder="Password"
                        name="password"
                        type="password"
                        valid={passwordState === "valid"}
                        invalid={passwordState === "invalid"}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          if (e.target.value === "") {
                            setPasswordState("invalid");
                          } else {
                            setPasswordState("valid");
                          }
                          handleChange(e);
                        }}
                        value={password}
                      />
                      <div className="invalid-feedback">
                        Please Enter valid Password.
                      </div>
                    </Col>
                    <Col className="mb-3" md="6">
                      <label
                        className="form-control-label"
                        htmlFor="validationCustom05"
                      >
                        Confirm Password
                      </label>
                      {/* {addEdit === "edit" ? (
                        <Input
                          placeholder="Password"
                          type="password"
                          value="********"
                          disabled
                        />
                      ) : (
                        <Input
                          id="validationCustom05"
                          placeholder="Confirm Password"
                          name="confirm_password"
                          type="password"
                          valid={confirmPasswordState === "valid"}
                          invalid={confirmPasswordState === "invalid"}
                          onChange={(e) => {
                            setConfirmPassword(e.target.value);
                            if (
                              e.target.value === "" ||
                              e.target.value !== password
                            ) {
                              setConfirmPasswordState("invalid");
                            } else {
                              setConfirmPasswordState("valid");
                            }
                            handleChange(e);
                          }}
                          value={confirmPassword}
                        />
                      )} */}
                      <Input
                        id="validationCustom05"
                        placeholder="Confirm Password"
                        name="confirm_password"
                        type="password"
                        valid={confirmPasswordState === "valid"}
                        invalid={confirmPasswordState === "invalid"}
                        onChange={(e) => {
                          setConfirmPassword(e.target.value);
                          if (
                            e.target.value === "" ||
                            e.target.value !== password
                          ) {
                            setConfirmPasswordState("invalid");
                          } else {
                            setConfirmPasswordState("valid");
                          }
                          handleChange(e);
                        }}
                        value={confirmPassword}
                      />
                      <div className="invalid-feedback">Password not match</div>
                    </Col>
                  </div>

                  {/* <div className="form-row">
                    <Col className="mb-3" md="4">
                      <div className="custom_file_upload">
                        <label
                          className="form-control-label"
                          htmlFor="validationCustom07"
                        >
                          Profile(optional)
                        </label>
                        <Input
                          className="custom_file_input"
                          id="validationCustom07"
                          type="file"
                          accept=" .png, .jpeg, .jpg"
                          onChange={(e) => {
                            onChangeProfile(e);
                          }}
                        />
                        <Input
                          placeholder="Profile"
                          type="text"
                          value={profilePicName}
                        />
                        <div className="invalid-feedback">
                          Please Enter valid Profile image.
                        </div>
                      </div>
                    </Col>
                    {profilePicURL !== "" ? (
                      <Col className="mb-3" md="4">
                        <label
                          className="form-control-label"
                          htmlFor="validationCustom07"
                        >
                          Profile Preview
                        </label>
                        <div className="img_cls">
                          <img
                            className="rounded-circle imageFitCover"
                            height="140px"
                            width="140px"
                            src={profilePicURL}
                            alt="Profile"
                          />
                        </div>
                      </Col>
                    ) : (
                      ""
                    )}
                  </div> */}
                  <div className="form-row">
                    <Col md="12" className="text-right">
                      <Button
                        color="secondary"
                        type="button"
                        onClick={onCancel}
                      >
                        Cancel
                      </Button>
                      <Button
                        color="primary"
                        type="submit"
                        onClick={validateCustomStylesForm}
                      >
                        {disabled && (
                          <Spinner
                            as="span"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}{" "}
                        {addEdit === "edit" ? "Update" : "Create"}
                      </Button>
                    </Col>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default CustomerDetails;
