import React from "react";
import ReactDOM from "react-dom";
// react library for routing
import { BrowserRouter } from "react-router-dom";

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "@fullcalendar/common/main.min.css";
import "@fullcalendar/daygrid/main.min.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "select2/dist/css/select2.min.css";
import "quill/dist/quill.core.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// plugins styles downloaded
import "assets/vendor/nucleo/css/nucleo.css";
// core styles
import "assets/scss/argon-dashboard-pro-react.scss?v1.2.0";

import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
// import Auth from "components/AuthComponent/Auth";
import { Redirect, Route, Switch } from 'react-router';
import AdminLayout from "./layouts/Admin";
import AuthLayout from "./layouts/Auth";

/**
 * @description : Entry points for components.
 * @Author : Bhargav Lakhani
 * @since : 21 Dec 2021
 */

ReactDOM.render(
  <BrowserRouter>
    <ToastContainer />
    {/* <Route path="/login" /> */}
    {/* <Auth /> */}
    <Switch>
      <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
      <Route path="/" render={(props) => <AuthLayout {...props} />} />
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
