import React, { useEffect, useState } from 'react';
import AlternativeHeader from 'components/Headers/AlternativeHeader';
import { Button, Card, CardBody, CardHeader, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, Spinner } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { getUserList, updateAdminStatus, exportAdmin } from 'services/services';
import { useHistory } from 'react-router';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { toast } from 'react-toastify';
import { CSVLink } from 'react-csv';

const AdminList = () => {

    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [adminsList, setAdminsList] = useState('');
    const [adminId, setAdminId] = useState('');
    const [adminStatus, setAdminStatus] = useState();
    const [alert, setAlert] = useState(null);
    const [exportAdminList, setExportAdminList] = useState('');

    const history = useHistory();

    useEffect(() => {
        let params = { limit: perPage, page_no: 1, role_id: 2 };
        setLoading(true);
        getAllUser(params);
        exportAdminData();
    }, []);

    const onEdit = (userId) => {
        history.push({
            pathname: "/admin/admindetails/" + userId
        });
    }

    const columns = [
        {
            name: 'First Name',
            selector: row => row.firstName,
            sortable: true,
        },
        {
            name: 'Last Name',
            selector: row => row.lastName,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Created Date',
            maxWidth: "150px",
            center: true,
            selector: row => row.createdDate,
            sortable: true,
            cell: (row) => {
                if (row && row.createdDate) {
                    return (
                        <div className="d-flex justify-content-center">
                            {new Date(row.createdDate).toLocaleDateString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' })}
                        </div>
                    );
                }
            }
        },
        {
            name: 'Status',
            maxWidth: "150px",
            center: true,
            selector: row => row.status,
            cell: (row) => {
                return (
                    <div>
                        {row.status === 1 ?
                            <label className="custom-toggle custom-toggle-success mr-1" >
                                <input defaultChecked type="checkbox" onChange={() => deactivateAdmin(row.userId, row.status)} />
                                <span className="custom-toggle-slider rounded-circle" />
                            </label>
                            :
                            <label className="custom-toggle custom-toggle-success mr-1">
                                <input type="checkbox" onChange={() => activateAdmin(row.userId, row.status)} />
                                <span className="custom-toggle-slider rounded-circle" />
                            </label>
                        }
                    </div>
                )
            }
        },
        {
            name: 'Action',
            maxWidth: "120px",
            center: true,
            cell: (row) => {
                return (
                    <div className="d-flex justify-content-center">
                        <>
                            <Button className="mr-2 btn-primary" onClick={() => onEdit(row.userId)}>
                                <i className="fa fa-edit" />
                            </Button>
                        </>
                    </div>
                );
            }
        },
    ];

    const getAllUser = async (params) => {
        try {
            let response = await getUserList(params)
            if (response && response.status === 200) {
                setAdminsList(response.data.data);
                setTotalRows(response.data.TotalResult);
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            setAdminsList([]);
        }
    }

    const onSearch = async (e) => {
        setSearchInput(e.target.value);
        if (e.target.value.length > 2) {
            let params = { limit: perPage, page_no: 1, search: e.target.value, role_id: 2 };
            getAllUser(params);
        }
        else {
            let params = { limit: perPage, page_no: 1, role_id: 2 };
            getAllUser(params);
        }
    }

    const handlePageChange = page => {
        setLoading(true);
        let params = { limit: perPage, page_no: page, search: searchInput, role_id: 2 };
        getAllUser(params);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        let params = { limit: newPerPage, page_no: page, search: searchInput, role_id: 2 };
        getAllUser(params);
        setPerPage(newPerPage);
    };

    const activateAdmin = (userId, status) => {
        setAdminId(userId);
        setAdminStatus(status);
        setAlert(
            <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Are you sure?"
                onConfirm={() => onConfirmation(userId, status)}
                showCancel
                onCancel={() => onCancel()}
                confirmBtnBsStyle="secondary"
                confirmBtnText="Yes"
                cancelBtnBsStyle="danger"
                cancelBtnText="Cancel"
            >
                {status === 0 ? "You want to activate this Admin!" : "You want to deactivate this Admin!"}
            </ReactBSAlert>
        );
    }

    const deactivateAdmin = (userId, status) => {
        setAdminId(userId);
        setAdminStatus(status);
        setAlert(
            <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Are you sure?"
                onConfirm={() => onConfirmation(userId, status)}
                showCancel
                onCancel={() => onCancel()}
                confirmBtnBsStyle="secondary"
                confirmBtnText="Yes"
                cancelBtnBsStyle="danger"
                cancelBtnText="Cancel"
            >
                {status === 0 ? "You want to activate this Admin!" : "You want to deactivate this Admin!"}
            </ReactBSAlert>
        );
    }

    const onCancel = () => {
        let params = { limit: perPage, page_no: 1, role_id: 2, search: searchInput };
        setAlert(null);
        setLoading(true);
        getAllUser(params);
    }

    const onConfirmation = async (userId, status) => {
        let admin_status = status === 1 ? 0 : 1;
        let data = { status: admin_status };
        let success_msg = status === 0 ? "User activated successfully." : "User deactivated successfully.";
        // const id = userId;

        try {
            let res = await updateAdminStatus(userId, data);
            if (res && res.status === 200) {
                toast.success(success_msg, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000
                });
            }
        } catch (error) {
            if (error && error.response && error.response.status !== 500) {
                toast.error(error.response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000
                });
            } else {
                toast.error('Oops something went wrong...', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000
                });
            }
        }
        let params = { limit: perPage, page_no: 1, role_id: 2, search: searchInput };
        setLoading(true);
        setTimeout(() => {
            getAllUser(params);
        }, 2000);

        setAlert(null)
    }

    const addAdmin = () => {
        let path = `/admin/adminDetails/add`;
        history.push(path);
    }

    const exportAdminData = async () => {
        try {
            let response = await exportAdmin();
            if (response && response.status === 200) {
                setExportAdminList(response.data.data);
            }
        } catch (err) {
            setLoading(false);
        }
    }


    return (
        <>
            {alert}
            <AlternativeHeader parentComponent="User Management" pageTitle="Admin" />

            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader>
                                <h3 className="mb-0">Admin</h3>
                            </CardHeader>
                            <CardBody>
                                <Row className='mb-3'>
                                    <Col md="3">
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fa fa-search" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder="Search" name="searchInput" type="text" onChange={onSearch} autoComplete="off" value={searchInput || ""} />
                                        </InputGroup>
                                    </Col>
                                    <Col md="9">
                                        <div className="d-flex justify-content-end align-items-center flex-wrap">
                                            <Button type="button" className="btn btn-info mx-2" onClick={addAdmin}> Add Admin </Button>
                                            <CSVLink data={exportAdminList} filename={"AdminList.csv"} className="btn btn-info">Export</CSVLink>
                                            {/* <Button type="button" className="btn btn-info mx-2" onClick={exportAdmin}> Export </Button> */}
                                        </div>
                                    </Col>
                                </Row>
                                <DataTable
                                    columns={columns}
                                    data={adminsList}
                                    progressPending={loading}
                                    progressComponent={<Spinner as="span" size="lg" role="status" aria-hidden="true" />}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                    paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                                    striped
                                />
                            </CardBody>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
}

export default AdminList;