import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import { Button, Card, CardHeader, CardBody, FormGroup, Form, Input, InputGroupAddon, InputGroupText, InputGroup, Container, Row, Col, FormFeedback, Spinner } from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import { validateEmail, validatePassword } from "shared/validation";
import { signIn } from "services/services";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

function Login() {  
  const [focusedEmail, setfocusedEmail] = useState(false);
  const [focusedPassword, setfocusedPassword] = useState(false);
  const [errors, setErrors] = useState("blank");
  const [emailId, setEmailId] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [passIcon, setPassIcon] = useState("fa fa-eye");
  const [passType, setPassType] = useState("password");
  const [disabled, setDisabled] = useState(false);

  const [btnDisable, setBtnDisable] = useState(true);


  let history = useHistory();

  useEffect(() => {
    setRememberMe(localStorage.getItem('remember'));
  }, [])

  var regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

  const handleChange = (e) => {
    let error;
    if (e.target.name === 'email') {
      setEmailId(e.target.value.trim().replace(regex, ''))
      error = validateEmail(e.target.value);
      setErrors(error);
      setBtnDisable(true)
    } else if (e.target.type === "checkbox" && !e.target.checked) {
      setRememberMe(false);
      console.log(e.target.checked);
    } else if (e.target.type === "checkbox" && e.target.checked) {
      setRememberMe(true);
    } else {
      setPassword(e.target.value.trim().replace(regex, ''))
      error = validatePassword(e.target.value);
      setErrors(error);
    }
    if(!errors){
      setBtnDisable(false)
    }
  }


  const showHide = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (passType === "text") {
      setPassType("password")
      setPassIcon("fa fa-eye")
    } else {
      setPassType("text")
      setPassIcon("fa fa-eye-slash")
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);
    if(emailId === ""){
      toast.error("Email is Required.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000
      });
    }
    const params = { email: emailId, password: password }
    try {
      if (!errors) {
        let response = await signIn(params);
        console.log("loginres ",response)
        if (response && response.status === 200) {
          localStorage.setItem('token', response.data.data.token);
          localStorage.setItem('fullName', response.data.data.full_name);
          localStorage.setItem('email', response.data.data.email);
          localStorage.setItem('role', response.data.data.roleId);
          localStorage.setItem('profile', response.data.data.profile_pic);
          localStorage.setItem('remember', rememberMe);
          toast.success('Successfully logged in.', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000
          });

          setTimeout(() => {
            history.replace("/admin/dashboard")
          }, 1000);
        }
      }
    } catch (err) {
      if (err && err.response && err.response.status !== 500) {
        toast.error(err.response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000
        });
      } else {
        toast.error('Oops something went wrong...', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000
        });
      }
    }
    setTimeout(() => {
      setDisabled(false);
    }, 500);
  }

  return (
    <>
      <AuthHeader title="" />
      {/* lead="Use these awesome forms to login or create new account in your project for free." */}
      <Container className="mt--9">
        <Row className="justify-content-center">
          <Col lg="6" md="8" className="mt--8">
            <h1 className="text-white text-center pb-4">Welcome!</h1>
            <Card className="bg-secondary border-0 mb-0">
              <CardHeader className="bg-transparent py-4">
                <div className="btn-wrapper text-center">
                  <img className="login_logo" alt="Logo" src={require("assets/img/brand/Fevicon.png").default} width="114" height="117px"/>
                </div>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-3">
                <div className="text-center text-muted mb-4">
                  <small>Sign In</small>
                </div>
                <Form role="form" onSubmit={handleSubmit}>
                  <FormGroup
                    className={classnames("mb-3", {
                      focused: focusedEmail,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="email"
                        placeholder="Email Address"
                        type="text"
                        onFocus={() => setfocusedEmail(true)}
                        onBlur={() => setfocusedEmail(true)}
                        onChange={handleChange}
                        value={emailId || ''}
                        invalid={errors && errors.email}
                        style={{color:"black"}}
                      />
                      <FormFeedback tooltip>{errors && errors.email}</FormFeedback>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: focusedPassword,
                    })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative position-relative password-field">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="password"
                        placeholder="Password"
                        type={passType}
                        onFocus={() => setfocusedPassword(true)}
                        onBlur={() => setfocusedPassword(true)}
                        onChange={handleChange}
                        value={password || ''}
                        invalid={errors && errors.password}
                        style={{color:"black"}}
                      />
                      {/* <InputGroupAddon addonType="append">
                        <InputGroupText className="h-100">
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon> */}
                      <div className="showPassIcon" onClick={showHide}><i className={passIcon}></i></div>
                      <FormFeedback tooltip>{errors && errors.password}</FormFeedback>
                    </InputGroup>
                  </FormGroup>
                  {/* <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="customCheckLogin"
                      type="checkbox"
                      checked={rememberMe}
                      onChange={handleChange}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheckLogin"
                    >
                      <span className="text-muted">Remember me</span>
                    </label>
                  </div> */}
                  <div className="text-center">
                    <Button className="my-4" color="info" type="submit">
                    {disabled && <Spinner as="span" size="sm" role="status" aria-hidden="true" />}{' '} Login
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col xs="12" >
                <div className="d-flex w-100 justify-content-between">
                  <Link to={{ pathname: "/auth/forgotpassword" }} className="text-light">Forgot password?</Link>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;