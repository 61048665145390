
/**
 * @description : common header function for axios headers.
 * @author : Bhargav Lakhani
 * @since : 21 Dec 2021
 */

 export function getHeaderOptions() {
    let headerOptions = {
        "Content-Type": 'application/json'
    }
    let token = localStorage.getItem('token');
    if (token) {
        headerOptions = {
            ...headerOptions,
            Authorization: `Bearer ${token}`
        }
    }
    return headerOptions;
}

export function getHeaderOptions2() {
    let headerOptions = {'content-type': 'multipart/form-data'}
    let token = localStorage.getItem('token');
    if (token) {
        headerOptions = {
            ...headerOptions,
            Authorization: `Bearer ${token}`
        }
    }
    return headerOptions;
}