import AlternativeHeader from "components/Headers/AlternativeHeader";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Row,
  Spinner,
  InputGroup,
  FormGroup,
} from "reactstrap";
import { changePassword } from "services/services";
import { validatePassword } from "shared/validation";

const ChangePassword = () => {
  const [disabled, setDisabled] = useState(false);
  const [errors, setErrors] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordState, setPasswordState] = useState(null);
  const [oldPassword, setOldPassword] = useState("");
  const [oldPasswordState, setOldPasswordState] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [cnfPassMsg, setCnfPassMsg] = useState("Confirm Password Is Required.");
  const [confirmPasswordState, setConfirmPasswordState] = useState(null);
  const [passIcon, setPassIcon] = useState("fa fa-eye");
  const [passType, setPassType] = useState("password");
  const [focusedPassword, setfocusedPassword] = useState(false);
  const [oldPasswordShown, setOldPasswordShown] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const history = useHistory();
  const [formData, setFormData] = useState({
    old_password: "",
    password: "",
    confirm_password: "",
  });

  const onCancel = () => {
    let path = `/admin/myprofile`;
    history.push(path);
  };

  const validateCustomStylesForm = () => {
    if (oldPassword === "") {
      setOldPasswordState("invalid");
      setErrors(true);
    }
    if (password === "") {
      setPasswordState("invalid");
      setErrors(true);
    }
    if (confirmPassword === "") {
      setConfirmPasswordState("invalid");
      setErrors(true);
    }
  };

  const handleChange = (e) => {
    let error;
    if (e.target.name === "oldpassword") {
      error = validatePassword(e.target.value);
      if (error || e.target.value === "") {setOldPasswordState("invalid");}
    } else if (e.target.name === "password") {
      error = validatePassword(e.target.value);
      if (error || e.target.value === "") setPasswordState("invalid");
    } else if (e.target.name === "confirm_password") {
      error = validatePassword(e.target.value);
      if (error || e.target.value === ""){
        setConfirmPasswordState("invalid");
      }else if (e.target.value !== password) {
        setCnfPassMsg("Passwords aren't matching");
      }
    }
  };

  const handelSubmit = async (e) => {
    e.preventDefault();
    if (!errors) {
      setDisabled(true);
      let data = new FormData();
      // console.log("here data => ",data);
      // console.log(oldPassword);

      data.append("old_password", oldPassword);
      data.append("password", password);
      data.append("confirm_password", confirmPassword);
      console.log(data);
      //   setFormData({
      //     old_password: oldPassword,
      //     password: password,
      //     confirm_password: confirmPassword,
      //   });
      try {
        let res = await changePassword(formData);
        if (res && res.status === 200) {
          toast.success("Password changed successfully.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          setTimeout(() => {
            setDisabled(false);
            localStorage.removeItem("token");
            history.replace({ pathname: "/auth/login" });
          }, 500);
        }
      } catch (err) {
        setTimeout(() => {
          setDisabled(false);
        }, 500);
        if (err.response.status !== 500) {
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        } else {
          toast.error("Oops something went wrong...", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      }
    }
  };

  const showHideOldPassword = (e) => {
    setOldPasswordShown(!oldPasswordShown);
  };

  const showHideNewPassword = (e) => {
    setNewPasswordShown(!newPasswordShown);
  };

  const showHideConfirmPassword = (e) => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  // const showHide = (e) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   console.log(e.target.name);
  //   if (passType === "text") {
  //     setPassType("password");
  //     setPassIcon("fa fa-eye");
  //   } else {
  //     setPassType("text");
  //     setPassIcon("fa fa-eye-slash");
  //   }
  // };

  return (
    <>
      <AlternativeHeader
        parentComponent="My Profile"
        pageTitle="Change Password"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Change Password</h3>
              </CardHeader>
              <CardBody>
                <Form
                  className="needs-validation"
                  noValidate
                  onSubmit={handelSubmit}
                >
                  <div className="form-row">
                    <Col className="mb-3" md="4">
                      <label
                        className="form-control-label"
                        htmlFor="validationCustom03"
                      >
                        Old Password
                      </label>
                      {/* <FormGroup
                        className={classnames({
                          focused: focusedPassword,
                        })}
                      > */}
                      <InputGroup className="password-field">
                        <Input
                          id="validationCustom03"
                          placeholder="Password"
                          name="oldpassword"
                          type={oldPasswordShown ? "text" : "password"}
                          valid={oldPasswordState === "valid"}
                          invalid={oldPasswordState === "invalid"}
                          onFocus={() => setfocusedPassword(true)}
                          onBlur={() => setfocusedPassword(true)}
                          onChange={(e) => {
                            setOldPassword(e.target.value);
                            setFormData((prevState) => {
                              return {
                                ...prevState,
                                old_password: e.target.value,
                              };
                            });
                            if (e.target.value === "") {
                              setOldPasswordState("invalid");
                            } else {
                              setOldPasswordState("valid");
                            }
                            handleChange(e);
                          }}
                          value={oldPassword}
                        />
                        <div
                          className="showPassIcon"
                          onClick={showHideOldPassword}
                          name="popop"
                        >
                          <i
                            className={
                              oldPasswordShown ? "fa fa-eye-slash" : "fa fa-eye"
                            }
                          ></i>
                        </div>
                        <div className="invalid-feedback">
                        Old Password Is Required.
                      </div>
                      </InputGroup>
                      {/* </FormGroup> */}
                      {/* <InputGroup className="input-group-merge input-group-alternative position-relative">
                        <Input
                          id="validationCustom03"
                          placeholder="Password"
                          name="oldpassword"
                          type={passType}
                          valid={oldPasswordState === "valid"}
                          invalid={oldPasswordState === "invalid"}
                          onFocus={() => setfocusedPassword(true)}
                          onBlur={() => setfocusedPassword(true)}
                          onChange={(e) => {
                            setOldPassword(e.target.value);
                            setFormData((prevState) => {
                              return {
                                ...prevState,
                                old_password: e.target.value,
                              };
                            });
                            if (e.target.value === "") {
                              setOldPasswordState("invalid");
                            } else {
                              setOldPasswordState("valid");
                            }
                            handleChange(e);
                          }}
                          value={oldPassword}
                        />
                        <div
                          className="showPassIcon"
                          onClick={showHide}
                          // style={{ top: "15px" }}
                        >
                          <i className={passIcon}></i>
                        </div>
                      </InputGroup> */}
                     
                    </Col>
                    <Col className="mb-3" md="4">
                      <label
                        className="form-control-label"
                        htmlFor="validationCustom04"
                      >
                        Password
                      </label>
                      <InputGroup className="password-field">
                        <Input
                          id="validationCustom04"
                          placeholder="Password"
                          name="password"
                          type="password"
                          type={newPasswordShown ? "text" : "password"}
                          valid={passwordState === "valid"}
                          invalid={passwordState === "invalid"}
                          onChange={(e) => {
                            setPassword(e.target.value);
                            setFormData((prevState) => {
                              return {
                                ...prevState,
                                password: e.target.value,
                              };
                            });
                            if (e.target.value === "") {
                              setPasswordState("invalid");
                            } else {
                              setPasswordState("valid");
                            }
                            handleChange(e);
                          }}
                          value={password}
                        />
                        <div
                          className="showPassIcon"
                          onClick={showHideNewPassword}
                        >
                          <i
                            className={
                              newPasswordShown ? "fa fa-eye-slash" : "fa fa-eye"
                            }
                          ></i>
                        </div>
                        <div className="invalid-feedback">
                        New Password Is Required.
                      </div>
                      </InputGroup>
                     
                    </Col>
                    <Col className="mb-3" md="4">
                      <label
                        className="form-control-label"
                        htmlFor="validationCustom05"
                      >
                        Confirm Password
                      </label>

                      <InputGroup className="password-field">
                        <Input
                          id="validationCustom05"
                          placeholder="Confirm Password"
                          name="confirm_password"
                          type={confirmPasswordShown ? "text" : "password"}
                          valid={confirmPasswordState === "valid"}
                          invalid={confirmPasswordState === "invalid"}
                          onChange={(e) => {
                            setConfirmPassword(e.target.value);
                            setFormData((prevState) => {
                              return {
                                ...prevState,
                                confirm_password: e.target.value,
                              };
                            });
                            if (
                              e.target.value === "" ||
                              e.target.value !== password
                            ) {
                              setConfirmPasswordState("invalid");
                            } else {
                              setConfirmPasswordState("valid");
                            }
                            handleChange(e);
                          }}
                          value={confirmPassword}
                        />
                        <div
                          className="showPassIcon"
                          onClick={showHideConfirmPassword}
                        >
                          <i
                            className={
                              confirmPasswordShown
                                ? "fa fa-eye-slash"
                                : "fa fa-eye"
                            }
                          ></i>
                        </div>
                        <div className="invalid-feedback">
                          {cnfPassMsg}
                        </div>
                      </InputGroup>
                    </Col>
                    <Col md="12" className="text-right my-3 align-self-end">
                      <Button
                        color="secondary"
                        type="button"
                        onClick={onCancel}
                      >
                        Cancel
                      </Button>
                      <Button
                        color="primary"
                        type="submit"
                        onClick={validateCustomStylesForm}
                      >
                        {disabled && (
                          <Spinner
                            as="span"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}{" "}
                        Update
                      </Button>
                    </Col>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default ChangePassword;
