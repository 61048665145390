import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import AlternativeHeader from "components/Headers/AlternativeHeader";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Spinner,
  Modal,
} from "reactstrap";
import DataTable, { createTheme } from "react-data-table-component";
import JoditEditor from "jodit-react";
import {
  getFlaggedPosts,
  flaggedPostAction,
  getStaticPages,
  getTemplates,
  updateStaticPage,
} from "services/services";
import { useHistory, Link } from "react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { toast } from "react-toastify";
import "./modal.css";

const StaticPage = () => {
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [staticPageList, setStaticPageList] = useState("");
  const [pageId, setPageId] = useState();
  const [alert, setAlert] = useState(null);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState("");
  const [modalType, setModalType] = useState();
  const toggle = () => setModal(!modal);
  const count = 0;
  const history = useHistory();
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    buttons: [
      "source",
      "|",
      "bold",
      "strikethrough",
      "underline",
      "italic",
      "|",
      "ul",
      "ol",
      "|",
      "outdent",
      "indent",
      "|",
      "font",
      "fontsize",
      "brush",
      "paragraph",
      "|",
      "table",
      "superscript",
      "subscript",
      "|",
      "align",
      "undo",
      "redo",
      "|",
      "hr",
      "eraser",
      "copyformat",
      "|",
      "symbol",
    ],
  };

  useEffect(() => {
    setLoading(true);
    getAllStaticPages();
  }, []);

  const view = (pageId) => {
    history.push({
      pathname: "/admin/staticpage/" + pageId,
    });
  };

  const columns = [
    {
      name: "Sr No.",
      center: true,
      selector: (row) => row?.pageId,
      sortable: true,
      // cell: (row, count) => {
      //   return ++count;
      // },
    },
    {
      name: "Page Title",
      selector: (row) => row?.pageTitle,
      sortable: true,
      center: true,
    },
    {
      name: "Content",
      selector: (row) => row?.pageId,
      center: true,
      cell: (row) => {
        return (
          <div className="d-flex justify-content-center text-center m-1">
            <>
                <Button
                  className="mr-2 btn-info btn-sm"
                  onClick={() => displayModel(row?.pageId, row?.content, "view")}
                >
                  View Content
                </Button>
            </>
          </div>
        );
      },
    },
    {
      name: "Action",
      center: true,
      maxWidth: "300px",
      cell: (row) => {
        return (
          <div className="d-flex justify-content-center text-center m-1">
            <>
              <Button
                className="mr-2 btn-info btn-sm"
                onClick={() => displayModel(row?.pageId, row?.content, "edit")}
              >
                Edit
              </Button>
            </>
          </div>
        );
      },
    },
  ];

  const onAction = async (type) => {
    if (type === 1) {
      let data = { content: content };
      toggle();
      try {
        let res = await updateStaticPage(pageId, data);
        if (res && res.status === 200) {
          toast.success("Updated Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          setLoading(true);
          setTimeout(() => {
            getAllStaticPages();
          }, 1000);
        }
      } catch (error) {
        if (error && error.response && error.response.status !== 500) {
          console.log(error);
          toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        } else {
          toast.error("Oops something went wrong...", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      }
    } else {
      console.log("hi");
      toggle();
    }
  };

  const getAllStaticPages = async () => {
    try {
      let response = await getTemplates({ template_type: 3 });
      console.log(response.data.data);
      if (response && response.status === 200) {
        setStaticPageList(response.data.data);
        setTotalRows(response.data.TotalResult);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setStaticPageList([]);
    }
  };

  const displayModel = async (pageId, row, type) => {
    console.log(row);
    setPageId(pageId);
    setModalData(row);
    setContent(row);
    setModalType(type);
    toggle();
  };

  function createMarkup() {
    return { __html: modalData };
  }

  return (
    <>
      <AlternativeHeader parentComponent="CMS" pageTitle="Static Page" />

      <Modal
        className="modal-dialog-centered"
        isOpen={modal}
        toggle={toggle}
        modalTransition={{ timeout: 100 }}
        scrollable={true}
        // fullscreen={true}
        size="xl"
        // dialogClassName="myModalStyle"
        style={{ width: "90%", maxWidth: "none", height: "90%" }}
      >
        <div className="modal-header p-2">
          {/* <h6 className="modal-title" id="modal-title-default">
            {modalData?.experience_description}
          </h6> */}
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggle}
          >
            <span aria-hidden={true} style={{ fontSize: "25px" }}>
              ×
            </span>
          </button>
        </div>
        <div className="modal-body p-2">
          <div className="justify-content-center text-center p-2" id="content">
            {modalType === "view" ? (
              <p dangerouslySetInnerHTML={createMarkup()} />
            ) : (
              <>
                <JoditEditor
                  ref={editor}
                  value={content}
                  config={config}
                  tabIndex={1} // tabIndex of textarea
                  onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                  onChange={(newContent) => {}}
                />
                <div className="m-2">
                  <Button
                    className="m-2 btn btn-info"
                    onClick={() => onAction(1)}
                  >
                    Update
                  </Button>
                  <Button
                    className="m-2 btn btn-danger "
                    onClick={() => onAction(0)}
                  >
                    Cancel
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>

      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <h3 className="mb-0">Static Page</h3>
              </CardHeader>
              <CardBody>
                <DataTable
                  columns={columns}
                  data={staticPageList}
                  progressPending={loading}
                  progressComponent={
                    <Spinner
                      as="span"
                      size="lg"
                      role="status"
                      aria-hidden="true"
                    />
                  }
                  //   theme="solarized"
                  striped
                  //   customStyles={customStyles}
                />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default StaticPage;
