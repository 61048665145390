import AuthHeader from "components/Headers/AuthHeader";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Modal,
  CardFooter,
} from "reactstrap";
import { getExperiancebyId } from "services/services";
import profile from "../../../assets/img/theme/user_profile.png";
import appstore from "../../../assets/img/icons/common/AppStore.png";
import playstore from "../../../assets/img/icons/common/PlayStore.png";

const ViewExperiences = (props) => {
  const [imageUrl, setImageUrl] = useState("");
  const [experinaceId, setExperianceId] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [description, setDescription] = useState("");
  const [ownerFirstName, setOwnerFirstName] = useState("");
  const [ownerLastName, setOwnerLastName] = useState("");
  const [ownerProfile, setOwnerProfile] = useState("");
  const [experianceType, setExperianceType] = useState("");
  const [show, setShow] = useState(true);
  const [load, setLoad] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState("");
  const [modalType, setModalType] = useState();
  const toggle = () => setModal(!modal);

  useEffect(() => {
    const urlId = props.location.pathname.substring(
      props.location.pathname.lastIndexOf("/") + 1
    );
    setExperianceId(urlId);
    getExperiance(urlId);
    setTimeout(() => {
      setLoad(!load);
    }, 150);
    getapps();
  }, []);

  const getapps = async () => {
    console.log("dats");
    const relatedApps = await navigator.getInstalledRelatedApps();
    relatedApps.forEach((app) => {
      console.log("dd");
      console.log(app.id, app.platform, app.url);
    });
  };
  const getExperiance = async (urlId) => {
    const id = urlId;
    try {
      let res = await getExperiancebyId(id);
      if (res && res.status === 200) {
        console.log(res.data);
        setImageUrl(res.data.data.experienceDetails.mediaFile);
        console.log(res.data.data.experienceDetails.mediaFile);
        setCreatedDate(res.data.data.createdAt);
        setDescription(res.data.data.experienceDetails.experienceDescription);
        if (res.data.data.originalOwner) {
          setOwnerFirstName(res.data.data.originalOwner.firstName);
          setOwnerLastName(res.data.data.originalOwner.lastName);
          setOwnerProfile(res.data.data.originalOwner.profilePic);
        } else {
          setOwnerFirstName(res.data.data.owner.firstName);
          setOwnerLastName(res.data.data.owner.lastName);
          setOwnerProfile(res.data.data.owner.profilePic);
        }
        setExperianceType(res.data.data.experienceDetails.experienceType);
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const addDefaultProfile = (e) => {
    e.target.src = profile;
  };

  const handleVideoEnd = () => {
    console.log("Finished");
  };

  const handlePlay = () => {
    console.log("playing");
  };

  const showTime = (e) => {
    console.log(e.target.currentTime);
    if (e.target.currentTime > 6) {
      // setShow(!show);
      e.target.pause();
      setModal(true);
    }
  };

  const showImgTime = (e) => {
    setTimeout(() => {
      // setShow(!show);
      toggle();
    }, 2000);
  };

  return (
    <>
      <AuthHeader title="" />
      <Container className="mt--9">
        <Row className="justify-content-center">
          <Col md="8" className="mt--8">
            {/* <h1 className="text-white text-center pb-4">Experiance!</h1> */}
            {load && (
              <Card className="bg-secondary border-0 mb-0">
                <CardHeader className="bg-transparent py-4">
                  <div className="btn-wrapper text-center">
                    <img
                      className="login_logo"
                      alt="Logo"
                      src={require("assets/img/brand/Logo.svg").default}
                    />
                  </div>
                </CardHeader>
                {show ? (
                  <CardBody className="px-0 pt-0 pb-lg-3">
                    <div className="experiance_box">
                      {experianceType === 1 ? (
                        <img
                          className="mw-100"
                          alt="Experiance"
                          src={imageUrl}
                          onLoad={showImgTime}
                        />
                      ) : (
                        // <iframe
                        //   src={imageUrl}
                        //   title="title"
                        //   id="video"
                        //   onEnded={handleVideoEnd}
                        //   onPlay={handlePlay}
                        // ></iframe>
                        <video
                          height="400px"
                          width="400px"
                          controls
                          onTimeUpdate={showTime}
                        >
                          <source src={imageUrl} type="video/mp4" />
                          <source src={imageUrl} type="video/ogg" />
                          Your browser does not support the video tag.
                        </video>
                      )}
                      <div className="owner_details">
                        <img
                          src={ownerProfile}
                          alt="profile"
                          onError={addDefaultProfile}
                        />
                        <div className="ownername">
                          <h4>
                            {ownerFirstName} {ownerLastName}
                          </h4>
                          <small>
                            {new Date(createdDate).toLocaleDateString("en-GB", {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            })}
                            {" AT "}
                            {new Date(createdDate)
                              .toLocaleTimeString()
                              .slice(0, 5)}
                          </small>
                        </div>
                      </div>
                      <div className="text-left text-muted experiance_disc">
                        <small>{description}</small>
                      </div>
                    </div>
                  </CardBody>
                ) : (
                  <CardBody className="px-0 pt-0 pb-lg-3">
                    <div className="experiance_box text-center align-center">
                      PLease download Our Applicaton for more details
                    </div>
                  </CardBody>
                )}
                {/* <CardFooter className="text-center pt-0">
                  <img
                    alt="playstore"
                    className="navbar-brand-img"
                    src={playstore}
                    height="30%"
                    width="30%"
                    style={{ margin: "0px 10px" }}
                  />
                  <img
                    alt="appstore"
                    className="navbar-brand-img"
                    src={appstore}
                    height="30%"
                    width="30%"
                    style={{ margin: "0px 10px" }}
                  />
                </CardFooter> */}
              </Card>
            )}
          </Col>
        </Row>
      </Container>
      {/* {!show && (
        <Container
          className="mt--100 ml--100"
          style={{
            background: "white",
            width: "100%",
            height: "100vh",
            marginRight: "0px ",
          }}
        >
          <Row>
            <Col md="12">
              <div
                style={{ background: "white", width: "100%", height: "100vh" }}
              >
                helloo
              </div>
            </Col>
          </Row>
        </Container>
      )} */}

      <Modal
        className="modal-dialog-centered"
        isOpen={modal}
        toggle={toggle}
        modalTransition={{ timeout: 100 }}
        scrollable={true}
        // fullscreen={true}
        // size="xl"
        className=""
        // dialogClassName="myModalStyle"
        style={{
          width: "100vw",
          maxWidth: "none",
          height: "100vh",
          backgroundColor: "rgba(0,0,0,0.5)",
          textAlign: "center",
          alignSelf: "center",
          alignItems: "center",
          margin: "0px auto",
          // background: "black",
        }}
        // backdropOpacity={0.1}
        backdrop="static"
        centered={true}
      >
        <div
          className="modal-body p-2"
          style={{
            // background: "rgba(0, 151, 19, 0.1)",
            // opacity: "0.9",
            // width: "40vw",
            fontSize: "1rem",
          }}
        >
          <div className="justify-content-center text-center p-2" id="content">
            <img
              className="login_logo"
              alt="Logo"
              src={require("assets/img/brand/Logo.svg").default}
            />
            <div className="text-dark pt-3 pb-2 font-weight-bold">
              Konnektz Is Better on the App
            </div>
            <div>
              View more Experinaces. Share your Experinaces. Make friends. Chat
              with friends. Only on the app.
            </div>
            <div>
              <img
                alt="playstore"
                className="navbar-brand-img"
                src={playstore}
                height="30%"
                width="30%"
                style={{ margin: "20px 10px 0px 10px" }}
              />
              <img
                alt="appstore"
                className="navbar-brand-img"
                src={appstore}
                height="30%"
                width="30%"
                style={{ margin: "20px 10px 0px 10px" }}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ViewExperiences;
